import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const VersionNew = () => {
  let navigate = useNavigate();

  // 편집 가능한 요소
  const [versionTitle, setVersionTitle] = useState("");
  const [versionLog, setVersionLog] = useState("");
  const [versionIsEssential, setVersionIsEssential] = useState(true);
  const [versionDate, setVersionDate] = useState(null);

  const modifyVersionInfo = async () => {
    try {
      const { data } = await axios.post(
        "/api/company/version",
        JSON.stringify({
          title: versionTitle.toString(),
          log: versionLog.toString(),
          isEssential: versionIsEssential.toString(),
          activeDate: versionDate.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 201) {
        alert("정보수정이 완료되었습니다");
        navigate(-1);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {}, []);

  function getImg(data, type) {
    const imageArr = data.filter((item) => item.type === type);
    if (imageArr.length) {
      return imageArr[0].url;
    }
    return null;
  }
  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">버전 생성하기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            console.log("gkdl");
            modifyVersionInfo();
          }}
        >
          <div className="mb-3">
            <label className="form-label">버전명</label>
            <input
              placeholder={"1.0.0"}
              defaultValue={versionTitle}
              type="text"
              className="form-control"
              required={true}
              onChange={(event) => setVersionTitle(event.target.value)}
            />
          </div>
          <div className={"mb-3"}>
            <label className={"form-label"} htmlFor={"versionLogInput"}>
              업데이트 로그
            </label>
            <textarea
              className="form-control"
              id="versionLogInput"
              rows={3}
              onChange={(event) => setVersionLog(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="versionIsEssentialInput" className="form-label">
              필수여부
            </label>
            <select
              required={true}
              className="form-select"
              value={versionIsEssential}
              onChange={(event) => setVersionIsEssential(event.target.value)}
            >
              <option value={true}>필수</option>
              <option value={false}>선택</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">적용일자</label>
            <input
              onChange={(event) => setVersionDate(event.target.value)}
              required={true}
              className="form-control"
              value={versionDate}
              type={"date"}
              max={"9999-12-31"}
            />
          </div>
          <hr />
          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default VersionNew;
