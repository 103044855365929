import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components";
import moment from "moment";

const MyPage = () => {
  let navigate = useNavigate();

  // 드롭다운 요소
  const [adminGradeList, setAdminGradeList] = useState([]);
  const [adminStateList, setAdminStateList] = useState([]);

  // 편집 불가능한 요소
  const [adminCreatedDate, setAdminCreatedDate] = useState("");
  const [adminGrade, setAdminGrade] = useState("");
  const [adminState, setAdminState] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");

  // 편집 가능한 요소
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  // 페이지 옵션 목록 호출
  const getOptionList = async () => {
    try {
      const { data } = await axios.get("/api/user/admin/list/grade");
      if (data.statusCode === 200) {
        setAdminGradeList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/api/user/admin/list/state");
      if (data.statusCode === 200) {
        setAdminStateList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 어드민 정보 호출
  const getAdminInfo = async () => {
    try {
      const { data } = await axios.get("/api/user/admin/info/my");

      setUserName(data.data.userName);
      setUserId(data.data.userId);
      setPhone(data.data.phone);
      setEmail(data.data.email);
      setNickname(data.data.admin.nickName);
      setAdminState(data.data.admin.adminState);
      setAdminGrade(data.data.admin.grade);
      setAdminCreatedDate(
        moment(data.data.admin.createdAt).format("YYYY-MM-DD")
      );
    } catch (e) {
      if (e.request.status === 401) {
        alert("허용된 접근이 아닙니다.");
      } else {
        console.log(e);
      }
      navigate("/");
    }
  };

  // 어드민 정보 업데이트 요청하기
  const modifyAdminInfo = async () => {
    if (password !== passwordCheck) {
      alert("비밀번호와 비밀번호확인이 서로 다릅니다");
      setPassword("");
      setPasswordCheck("");
      return false;
    }
    try {
      const { data } = await axios.put(
        "/api/user/admin/info/my",
        JSON.stringify({
          nickName: nickname.toString(),
          password: password.toString(),
          phone: phone.toString(),
          email: email.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getOptionList();
    getAdminInfo();
  }, []);

  return (
    <>
      <Header />
      <div className="d-flex container-fluid justify-content-center align-items-center my-5">
        <div className={"col-md-4 col"}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              modifyAdminInfo();
            }}
          >
            <div className="mb-3">
              <label htmlFor="adminCreatedDateInput" className="form-label">
                가입일
              </label>
              <input
                value={adminCreatedDate}
                disabled={true}
                type="text"
                className="form-control"
                id="adminCreatedDateInput"
                placeholder=""
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminGradeInput" className="form-label">
                관리자 레벨
              </label>
              <select
                className="form-select"
                disabled={true}
                value={adminGrade}
              >
                {adminGradeList.map((i, index) => (
                  <option key={index} value={i.value}>
                    {i.key}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="adminStateInput" className="form-label">
                관리자 상태
              </label>
              <select
                className="form-select"
                disabled={true}
                value={adminState}
              >
                {adminStateList.map((i, index) => (
                  <option key={index} value={i.value}>
                    {i.key}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="adminNameInput" className="form-label">
                이름
              </label>
              <input
                disabled={true}
                pattern="[가-힣]+"
                required={true}
                maxLength={6}
                type="text"
                className="form-control"
                id="adminNameInput"
                placeholder="이기톨"
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminIdInput" className="form-label">
                아이디
              </label>
              <input
                disabled={true}
                pattern="^[a-zA-Z0-9]+$"
                required={true}
                type="text"
                className="form-control"
                id="adminIdInput"
                placeholder="gittle"
                value={userId}
                onChange={(event) => setUserId(event.target.value)}
              />
            </div>
            <hr />
            <div className="mb-3">
              <label htmlFor="adminNickNameInput" className="form-label">
                별명
              </label>
              <input
                required={true}
                type="text"
                className="form-control"
                id="adminNickNameInput"
                placeholder="gittle"
                value={nickname}
                onChange={(event) => setNickname(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminPasswordInput" className="form-label">
                비밀번호
              </label>
              <input
                pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$"
                required={true}
                type="password"
                className="form-control"
                id="adminPasswordInput"
                placeholder="숫자, 영문, 특수문자 각 1개 이상 포함 8글자 이상"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminPasswordCheckInput" className="form-label">
                비밀번호 확인
              </label>
              <input
                pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$"
                required={true}
                type="password"
                className="form-control"
                id="adminPasswordCheckInput"
                placeholder="비밀번호와 동일하게 입력해주세요"
                value={passwordCheck}
                onChange={(event) => setPasswordCheck(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminPhoneInput" className="form-label">
                휴대폰 번호
              </label>
              <input
                value={phone}
                pattern="\d{11}"
                maxLength={11}
                required={true}
                type="tel"
                className="form-control"
                id="adminPhoneInput"
                placeholder="010xxxxxxxx"
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminEmailInput" className="form-label">
                이메일 주소
              </label>
              <input
                value={email}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required={true}
                type="email"
                className="form-control"
                id="adminEmailInput"
                placeholder="help@21market.kr"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <button className="w-100 btn btn-lg btn-primary" type={"submit"}>
              정보 수정
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default MyPage;
