import React, { useEffect, useState } from "react";
import { Header, Navbar } from "../../components";
import axios from "axios";
import moment from "moment";
import QueryString from "qs";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
  withSearchParams,
} from "react-router-dom";

const ProductList = () => {
  // 네비바 구성
  const route = [
    { name: "디바이스", link: "/device/deviceList" },
    { name: "브랜드", link: "/device/brandList" },
    { name: "시리즈", link: "/device/seriesList" },
    { name: "기종", link: "/device/productList" },
  ];

  // react-router v6 사용
  const navigate = useNavigate();
  const location = useLocation();

  // 드롭다운 요소
  const [deviceList, setDeviceList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);

  // 쿼리스트링 변수 선언
  const [searchParams, setSearchParams] = useSearchParams();

  // 어드민 검색결과 목록 변수 선언
  const [productList, setProductList] = useState({});

  // 페이지네이션 갯수 변수 선언
  const [paginationCount, setPaginationCount] = useState(0);

  // 필터 어드민 옵션
  const [deviceId, setDeviceId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [seriesId, setSeriesId] = useState("");
  const [title, setTitle] = useState("");
  const [isActive, setIsActive] = useState("");

  // 어드민 목록 api 요청
  const getSeriesList = async (
    take,
    page,
    deviceId,
    brandId,
    seriesId,
    title,
    isActive
  ) => {
    try {
      const { data } = await axios.get(
        "/api/product/admin/list?take=" +
          take +
          "&page=" +
          page +
          "&deviceId=" +
          deviceId +
          "&brandId=" +
          brandId +
          "&seriesId=" +
          seriesId +
          "&title=" +
          title +
          "&isActive=" +
          isActive
      );
      setProductList(data.data);
      setPaginationCount(Math.ceil(data.data.total / searchParams.get("take")));
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 옵션 목록 호출
  const getOptionList = async () => {
    try {
      const { data } = await axios.get("/api/product/admin/list/filter/device");
      if (data.statusCode === 200) {
        setDeviceList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/api/product/admin/list/filter/brand");
      if (data.statusCode === 200) {
        setBrandList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/api/product/admin/list/filter/series");
      if (data.statusCode === 200) {
        setSeriesList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지네이션 함수
  const setPagination = (pageNumber) => {
    searchParams.set("page", pageNumber);
    setSearchParams(searchParams);
  };

  // 페이지 첫 로딩시 take, page 확인해서 강제 리다이랙팅
  useEffect(() => {
    if (
      searchParams.get("take") === null ||
      searchParams.get("page") === null
    ) {
      navigate(
        "?take=" +
          10 +
          "&page=" +
          1 +
          "&deviceId=&brandId=&seriesId=&title=&isActive="
      );
    } else {
      getSeriesList(
        searchParams.get("take"),
        searchParams.get("page"),
        searchParams.get("deviceId") || "",
        searchParams.get("brandId") || "",
        searchParams.get("seriesId") || "",
        searchParams.get("title") || "",
        searchParams.get("isActive") || ""
      );
    }
    getOptionList();
  }, [location.search]);

  // 뷰
  return (
    <>
      <Header />
      <Navbar menu={route} />
      <main className={"container"}>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2 fw-bold">기종 목록</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <select
              className="form-select form-select-sm"
              value={searchParams.get("take")}
              onChange={(event) => {
                searchParams.set("take", event.target.value);
                searchParams.set("page", 1);
                setSearchParams(searchParams);
              }}
            >
              <option key={0} value={10}>
                10개씩 보기
              </option>
              <option key={1} value={30}>
                30개씩 보기
              </option>
              <option key={3} value={50}>
                50개씩 보기
              </option>
              <option key={4} value={100}>
                100개씩 보기
              </option>
            </select>
          </div>
        </div>
        <div className={"bg-light border my-3 py-3 "}>
          <form
            className={"align-items-center d-flex flex-column"}
            onSubmit={(event) => {
              event.preventDefault();
              searchParams.set("deviceId", deviceId);
              searchParams.set("brandId", brandId);
              searchParams.set("seriesId", seriesId);
              searchParams.set("title", title);
              searchParams.set("isActive", isActive);
              searchParams.set("page", 1);
              setSearchParams(searchParams);
            }}
            onReset={async (event) => {
              setDeviceId("");
              setBrandId("");
              setSeriesId("");
              setTitle("");
              setIsActive("");
              searchParams.set("deviceId", "");
              searchParams.set("brandId", "");
              searchParams.set("seriesId", "");
              searchParams.set("title", "");
              searchParams.set("isActive", "");
              setSearchParams(searchParams);
            }}
          >
            <div className={" d-flex flex-md-row flex-column mb-3 w-100"}>
              <div className={"col mx-2 mb-3 mb-md-0"}>
                <label className="form-label">디바이스</label>
                <select
                  className="form-select"
                  onChange={(event) => setDeviceId(event.target.value)}
                >
                  <option key={0} value={""}>
                    전체
                  </option>
                  {deviceList.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className={"col mx-2 mb-3 mb-md-0"}>
                <label className="form-label">브랜드</label>
                <select
                  className="form-select"
                  onChange={(event) => setBrandId(event.target.value)}
                >
                  <option key={0} value={""}>
                    전체
                  </option>
                  {brandList.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className={"col mx-2 mb-3 mb-md-0"}>
                <label className="form-label">시리즈</label>
                <select
                  className="form-select"
                  onChange={(event) => setSeriesId(event.target.value)}
                >
                  <option key={0} value={""}>
                    전체
                  </option>
                  {seriesList.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className={"col mx-2 mb-3 mb-md-0"}>
                <label htmlFor="adminNameInput" className="form-label">
                  기종명
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="adminNameInput"
                  placeholder="기종명을 입력해주세요"
                  onChange={(event) => setTitle(event.target.value)}
                />
              </div>
            </div>
            <div>
              <button className="btn btn-outline-dark mx-2 px-3" type="reset">
                초기화
              </button>
              <button className="btn btn-primary mx-2 px-4" type={"submit"}>
                검색
              </button>
            </div>
          </form>
        </div>
        {/* 태이블 시작 */}
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered caption-top text-nowrap m-0">
            <caption className={"text-dark"}>
              <div className={"d-flex justify-content-between"}>
                <span>
                  검색결과 :{" "}
                  <span className={"text-danger fw-bold"}>
                    {productList.total}
                  </span>
                  개
                </span>
                <div className="">
                  <Link
                    type="button"
                    className="btn btn-sm btn-primary"
                    to={"/device/productNew"}
                  >
                    + 기종 생성
                  </Link>
                </div>
              </div>
            </caption>
            <thead>
              <tr>
                <th>No</th>
                <th>ID</th>
                <th>디바이스</th>
                <th>브랜드명</th>
                <th>시리즈명</th>
                <th>대표기기명</th>
                <th>노출순서</th>
              </tr>
            </thead>
            <tbody>
              {productList.results?.length === 0 ? (
                <tr>
                  <td className={"text-center"} colSpan={100}>
                    검색결과가 없습니다.
                  </td>
                </tr>
              ) : (
                productList.results?.map((i, index) => (
                  <tr key={index}>
                    <td>
                      {(searchParams.get("page") - 1) *
                        searchParams.get("take") +
                        index +
                        1}
                    </td>
                    <td>{i.id}</td>
                    <td>{i.device.title}</td>
                    <td>{i.brand.title}</td>
                    <td>{i.series.title}</td>
                    <td>
                      <Link to={"/device/productDetail/" + i.id}>
                        {i.title}
                      </Link>
                    </td>
                    <td>{i.vorder}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {/* 태이블 끝 */}
        <nav aria-label="Page navigation example" className={"mt-4"}>
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <button
                className="page-link text-dark"
                onClick={() =>
                  setPagination(Number(searchParams.get("page")) - 1)
                }
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {[...Array(paginationCount)].map((i, index) => (
              <li className="page-item" key={index + 1}>
                <button
                  className={
                    Number(searchParams.get("page")) === index + 1
                      ? "page-link fw-bold"
                      : "page-link text-muted"
                  }
                  onClick={() => setPagination(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className="page-link text-dark"
                onClick={() =>
                  setPagination(Number(searchParams.get("page")) + 1)
                }
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </main>
    </>
  );
};

export default ProductList;
