import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const PickUpInfoNew = () => {
  let navigate = useNavigate();

  // 편집 가능한 요소
  const [pickUpTitle, setPickUpTitle] = useState("");
  const [pickUpStartDate, setPickUpStartDate] = useState(null);
  const [pickUpEndDate, setPickUpEndDate] = useState(null);

  const [pickUpOffStartDate, setPickUpOffStartDate] = useState(null);
  const [pickUpOffEndDate, setPickUpOffEndDate] = useState(null);

  const modifyPickUpInfo = async () => {
    try {
      const { data } = await axios.post(
        "/api/pickup/admin/info",
        JSON.stringify({
          startDate: pickUpStartDate.toString(),
          endDate: pickUpEndDate.toString(),
          title: pickUpTitle.toString(),
          offStartDate: pickUpOffStartDate?.toString(),
          offEndDate: pickUpOffEndDate?.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 201) {
        alert(
          "픽업 구역이 성공적으로 등록되었습니다.\n" +
            "상세보기에서 시간등록 및 픽업 구역을 매핑해주세요."
        );
        navigate(-1);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {}, []);

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">픽업구역 생성하기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            modifyPickUpInfo();
          }}
        >
          <div className="mb-3">
            <label className="form-label">픽업 구역명</label>
            <input
              placeholder={"송파구역"}
              defaultValue={pickUpTitle}
              type="text"
              className="form-control"
              required={true}
              onChange={(event) => setPickUpTitle(event.target.value)}
            />
          </div>
          <div className={"row"}>
            <div className="mb-3 col">
              <label className="form-label">운영시작일</label>
              <input
                onChange={(event) => setPickUpStartDate(event.target.value)}
                required={true}
                className="form-control"
                value={pickUpStartDate}
                type={"date"}
                max={"9999-12-31"}
              />
            </div>
            <div className={"col-auto my-auto"}>~</div>
            <div className="mb-3 col">
              <label className="form-label">운영종료일</label>
              <input
                onChange={(event) => setPickUpEndDate(event.target.value)}
                required={true}
                className="form-control"
                value={pickUpEndDate}
                type={"date"}
                max={"9999-12-31"}
              />
            </div>
            <div className={"col-auto mb-3 mt-auto"}>
              <button
                className={"btn btn-primary"}
                type={"button"}
                onClick={() => {
                  setPickUpStartDate("");
                  setPickUpEndDate("");
                }}
              >
                초기화
              </button>
            </div>
          </div>
          <div className={"row"}>
            <div className="mb-3 col">
              <label className="form-label">휴무시작일</label>
              <input
                onChange={(event) => setPickUpOffStartDate(event.target.value)}
                className="form-control"
                value={pickUpOffStartDate}
                type={"date"}
                max={"9999-12-31"}
              />
            </div>
            <div className={"col-auto my-auto"}>~</div>
            <div className="mb-3 col">
              <label className="form-label">휴무종료일</label>
              <input
                onChange={(event) => setPickUpOffEndDate(event.target.value)}
                className="form-control"
                value={pickUpOffEndDate}
                type={"date"}
                max={"9999-12-31"}
              />
            </div>
            <div className={"col-auto mb-3 mt-auto"}>
              <button
                className={"btn btn-primary"}
                type={"button"}
                onClick={() => {
                  setPickUpOffStartDate("");
                  setPickUpOffEndDate("");
                }}
              >
                초기화
              </button>
            </div>
          </div>
          <hr />
          <Link className="btn btn-outline-secondary me-3 px-4" to={-1}>
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default PickUpInfoNew;
