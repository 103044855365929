import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { Modal } from "../index";

const Header = () => {
  let navigate = useNavigate();
  const pathname = useLocation().pathname;
  const cookies = new Cookies();
  const [adminInfo, setAdminInfo] = useState({});

  useEffect(() => {
    if (!cookies.get("adminInfo")) {
      navigate("/login");
    }
    setAdminInfo(cookies.get("adminInfo"));
  }, []);

  const logout = async () => {
    try {
      const { data } = await axios.post("/api/auth/logout", {
        headers: { "content-type": "multipart/form-data" },
      });
      if (data === "OK") {
        await cookies.remove("adminInfo", { path: "/" });
        navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <nav
        className="navbar navbar-expand-md navbar-dark bg-dark sticky-top"
        aria-label="Fourth navbar example"
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/dashboard">
            gittle Admin
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample04"
            aria-controls="navbarsExample04"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarsExample04">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <Link
                  className={
                    pathname === "/dashboard" ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  to="/dashboard"
                >
                  대시보드
                </Link>
              </li>
              <li className="nav-item">
                <Link className={"nav-link"} to="/account/userList">
                  회원관리
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/device/deviceList">
                  기기관리
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/query/qnaList">
                  샐프체크관리
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to=""
                  id="dropdown04"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  매입관리
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                  <li>
                    <Link className="dropdown-item" to="/">
                      엑셀다운로드
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      주문등록
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/sellOrder/go">
                      픽업관리
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/sellOrder/ro">
                      택배관리
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/sellOrder/zone">
                      박스관리
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to=""
                  id="dropdown05"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  운영관리
                </Link>
                <ul className="dropdown-menu" aria-labelledby="dropdown05">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/management/pickUpInfoList"
                    >
                      기틀고운영
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      기틀존운영
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      푸시메세지관리
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      리뷰관리
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/notice/noticeList">
                      공지관리
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/management/info">
                      사이트관리
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  정산관리
                </a>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item dropdown ">
                <Link
                  className="nav-link dropdown-toggle active fw-bold"
                  to=""
                  id="adminProfile"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {adminInfo.userName}님
                </Link>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="adminProfile"
                >
                  <li>
                    <Link className="dropdown-item" to={"/myPage"}>
                      내정보
                    </Link>
                  </li>
                  <li>
                    <p
                      role={"button"}
                      className="dropdown-item m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#logout"
                    >
                      로그아웃
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Modal
        id={"logout"}
        title={"로그아웃"}
        subTitle={"정말로 로그아웃 하시겠습니까?"}
        confirm={logout}
        confirmText={"로그아웃"}
        closeText={"취소"}
      />
    </>
  );
};

export default Header;
