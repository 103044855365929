import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = (props) => {
  return (
    <div className="nav-scroller bg-body shadow-sm ">
      <nav className="nav nav-underline" aria-label="Secondary navigation">
        {props.menu.map((i, index) => (
          <Link key={index} className={"nav-link"} to={i.link}>
            {i.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default Navbar;
