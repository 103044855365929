import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

const NoticeDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [noticeId, setNoticeId] = useState("");
  const [noticeCreateDate, setNoticeCreateDate] = useState("");
  const [noticeUpdateDate, setNoticeUpdateDate] = useState("");
  // 편집 가능한 요소
  const [noticeTitle, setNoticeTitle] = useState("");
  const [noticeContents, setNoticeContents] = useState("");
  const [noticeIsActive, setNoticeIsActive] = useState(true);

  const getNoticeInfo = async () => {
    try {
      const { data } = await axios.get("/api/notice/admin/info/" + id);
      setNoticeId(data.data.id);
      setNoticeCreateDate(moment(data.data.createdAt).format("YYYY-MM-DD"));
      setNoticeUpdateDate(moment(data.data.updatedAt).format("YYYY-MM-DD"));
      setNoticeTitle(data.data.title);
      setNoticeContents(data.data.contents);
      setNoticeIsActive(data.data.isActive);
    } catch (e) {
      console.log(e);
    }
  };

  const modifyNoticeInfo = async () => {
    try {
      const { data } = await axios.patch(
        "/api/notice/admin/info/" + id,
        JSON.stringify({
          title: noticeTitle.toString(),
          contents: noticeContents.toString(),
          isActive: noticeIsActive.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getNoticeInfo();
  }, []);
  const editorRef = useRef(null);

  function getImg(data, type) {
    const imageArr = data.filter((item) => item.type === type);
    if (imageArr.length) {
      return imageArr[0].url;
    }
    return null;
  }
  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">공지사항 세부정보</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            console.log("gkdl");
            modifyNoticeInfo();
          }}
        >
          <div className="mb-3">
            <label className="form-label">id</label>
            <input
              defaultValue={noticeId}
              type="text"
              disabled={true}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">생성일</label>
            <input
              defaultValue={noticeCreateDate}
              type="text"
              disabled={true}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">업데이트일</label>
            <input
              defaultValue={noticeUpdateDate}
              type="text"
              disabled={true}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">공지사항명</label>
            <input
              placeholder={"기틀존 확장안내"}
              defaultValue={noticeTitle}
              type="text"
              className="form-control"
              required={true}
              onChange={(event) => setNoticeTitle(event.target.value)}
            />
          </div>
          <div className={"mb-3"}>
            <Editor
              apiKey="fbkxstal67p9xq8mz8yefagmcbvfmlwap2g3ckh9clppotc2"
              onInit={(evt, editor) => (editorRef.current = editor)}
              onEditorChange={(newValue, editor) => setNoticeContents(newValue)}
              value={noticeContents}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">공개여부</label>
            <select
              className="form-select"
              required={true}
              value={noticeIsActive}
              onChange={(event) => setNoticeIsActive(event.target.value)}
            >
              <option value={true}>공개</option>
              <option value={false}>비공개</option>
            </select>
          </div>
          <hr />
          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default NoticeDetail;
