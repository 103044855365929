import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const ProductNew = () => {
  let navigate = useNavigate();

  // 편집 불가능한 요소
  const [productId, setProductId] = useState("");
  const [productCreatedDate, setProductCreatedDate] = useState("");
  const [productUpdatedDate, setProductUpdatedDate] = useState("");

  // 편집 가능한 요소
  const [productTitle, setProductTitle] = useState("");
  const [productCodeName, setProductCodeName] = useState("");
  const [productVorder, setProductVorder] = useState(0);

  const [productDevice, setProductDevice] = useState(0);
  const [productBrand, setProductBrand] = useState(null);
  const [productSeries, setProductSeries] = useState(null);

  const [productAnswer, setProductAnswer] = useState("");
  const [productQuestion, setProductQuestion] = useState("");

  // 드롭다운 요소
  const [deviceList, setDeviceList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);

  const getDeviceList = async () => {
    try {
      const { data } = await axios.get("/api/product/admin/list/filter/device");
      if (data.statusCode === 200) {
        setDeviceList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBrandList = async (deviceId) => {
    setBrandList([]);
    try {
      const { data } = await axios.get(
        "/api/product/admin/list/filter/brand?deviceId=" + deviceId
      );
      if (data.statusCode === 200) {
        setBrandList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSeriesList = async (deviceId, brandId) => {
    setSeriesList([]);
    try {
      const { data } = await axios.get(
        "/api/product/admin/list/filter/series?deviceId=" +
          deviceId +
          "&brandId=" +
          brandId
      );
      if (data.statusCode === 200) {
        setSeriesList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const modifyProductInfo = async () => {
    try {
      const { data } = await axios.post(
        "/api/product/admin/info/",
        JSON.stringify({
          title: productTitle.toString(),
          vorder: productVorder.toString(),
          deviceId: productDevice.toString(),
          brandId: productBrand.toString(),
          seriesId: productSeries.toString(),
          codeName: productCodeName.toString(),
          answerLogic: productAnswer.toString(),
          questionLogic: productQuestion.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 201) {
        alert(
          "기종이 성공적으로 추가되었습니다.\n" +
            "기종과 [셀프체크]를 매핑 후에 그에 맞는 [단가표]를\n" +
            "등록하셔야 고객 화면에서 노출됩니다."
        );
        navigate(-1);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getDeviceList();
  }, []);

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">기종 생성하기</h1>
        </div>
        <form
          onSubmit={(event) => {
            modifyProductInfo();
            event.preventDefault();
          }}
        >
          <div className="mb-3">
            <label htmlFor="productDeviceInput" className="form-label">
              디바이스
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={productDevice}
              onChange={(event) => {
                setProductBrand(null);
                setProductSeries(null);
                getBrandList(event.target.value);
                setSeriesList([]);
                setProductDevice(event.target.value);
              }}
            >
              <option hidden={true} value={null}>
                디바이스를 선택해주세요
              </option>
              {deviceList.map((i, index) => (
                <option key={index} value={i.id}>
                  {i.title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="productBrandInput" className="form-label">
              브랜드
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={productBrand}
              disabled={brandList.length === 0}
              onChange={(event) => {
                setProductSeries(null);
                getSeriesList(productDevice, event.target.value);
                setProductBrand(event.target.value);
              }}
            >
              {brandList.length === 0 ? (
                <option key={0} value={null}>
                  선택할 수 없습니다
                </option>
              ) : (
                <>
                  <option hidden={true} value={null}>
                    브랜드를 선택해주세요
                  </option>
                  {brandList.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.title}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="productSeriesInput" className="form-label">
              시리즈
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={productSeries}
              disabled={seriesList.length === 0}
              onChange={(event) => setProductSeries(event.target.value)}
            >
              {seriesList.length === 0 ? (
                <option key={0} value={null}>
                  선택할 수 없습니다
                </option>
              ) : (
                <>
                  <option hidden={true} value={null}>
                    시리즈를 선택해주세요
                  </option>
                  {seriesList.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.title}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="productNameInput" className="form-label">
              기종 이름
            </label>
            <input
              placeholder={"갤럭시 z폴드 128GB"}
              defaultValue={productTitle}
              type="text"
              className="form-control"
              id="productNameInput"
              required={true}
              onChange={(event) => setProductTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="productCodeNameInput" className="form-label">
              모델명
            </label>
            <input
              placeholder={"MK1250"}
              defaultValue={productCodeName}
              type="text"
              className="form-control"
              id="productCodeNameInput"
              required={true}
              onChange={(event) => setProductCodeName(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="productVorderInput" className="form-label">
              노출 순서
            </label>
            <input
              min={0}
              max={999}
              defaultValue={99}
              onChange={(event) => setProductVorder(event.target.value)}
              value={productVorder}
              required={true}
              type="number"
              className="form-control"
              id="productVorderInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="productAnswerInput" className="form-label">
              답변로직
            </label>
            <input
              onChange={(event) => setProductAnswer(event.target.value)}
              value={productAnswer}
              required={true}
              type="text"
              className="form-control"
              id="productAnswerInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="productQuestionInput" className="form-label">
              질문 로직
            </label>
            <input
              onChange={(event) => setProductQuestion(event.target.value)}
              value={productQuestion}
              required={true}
              type="text"
              className="form-control"
              id="productQuestionInput"
            />
          </div>

          <Link className="btn btn-outline-secondary me-3 px-4" to={-1}>
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default ProductNew;
