import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Cookies from "universal-cookie";
import { Footer } from "../components";

const Login = () => {
  let navigate = useNavigate();
  const cookies = new Cookies();

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  // 로그인 요청
  const adminLogin = async () => {
    try {
      const { data } = await axios.post(
        "/api/auth/admin/login",
        JSON.stringify({
          userId: userId.toString(),
          password: password.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );

      cookies.set("adminInfo", data, {
        path: "/",
        expires: moment().add(1, "days").toDate(),
      });
      navigate("/");
    } catch (e) {
      if (e.request.status === 400) {
        alert("잘못된 아이디 또는 패스워드 입니다.");
      } else if (e.request.status === 403) {
        alert("활성화 되지 않은 회원입니다. 관리자에게 문의하세요");
      } else {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (cookies.get("adminInfo")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="d-flex container-fluid vh-100 justify-content-center align-items-center">
        <div className={"col-md-4 col"}>
          <h1 className="h3 mb-5 fw-bold text-center text-primary">
            21세기전파상 관리 시스템
          </h1>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              adminLogin();
            }}
          >
            <div className="mb-3">
              <label htmlFor="adminIdInput" className="form-label">
                아이디
              </label>
              <input
                pattern="^[a-zA-Z0-9]+$"
                required={true}
                type="text"
                className="form-control"
                id="adminIdInput"
                placeholder="아이디를 입력해 주세요"
                value={userId}
                onChange={(event) => setUserId(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminPasswordInput" className="form-label">
                비밀번호
              </label>
              <input
                required={true}
                type="password"
                className="form-control"
                id="adminPasswordInput"
                placeholder="비밀번호를 입력해 주세요"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>

            <button className="w-100 btn btn-lg btn-primary" type={"submit"}>
              로그인
            </button>
          </form>
          <Link to={"/signup"} className={"text-center text-decoration-none"}>
            <p className={"mt-3"}>회원가입 하기</p>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
