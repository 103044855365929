import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const DeviceDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  // 편집 불가능한 요소
  const [deviceId, setDeviceId] = useState("");
  const [deviceCreatedDate, setDeviceCreatedDate] = useState("");
  const [deviceUpdatedDate, setDeviceUpdatedDate] = useState("");

  // 편집 가능한 요소
  const [deviceTitle, setDeviceTitle] = useState("");
  const [deviceVorder, setDeviceVorder] = useState(0);
  const [deviceIsActive, setDeviceIsActive] = useState(true);
  const [deviceListImage, setDeviceListImage] = useState("");
  const [deviceListImageData, setDeviceListImageData] = useState(null);
  const [deviceSellImage, setDeviceSellImage] = useState("");
  const [deviceSellImageData, setDeviceSellImageData] = useState(null);

  // 어드민 정보 호출
  const getDeviceInfo = async () => {
    try {
      const { data } = await axios.get("/api/device/admin/info/" + id);
      setDeviceId(data.data.id);
      setDeviceCreatedDate(moment(data.data.createdAt).format("YYYY-MM-DD"));
      setDeviceUpdatedDate(
        moment(data.data.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      );
      setDeviceTitle(data.data.title);
      setDeviceVorder(data.data.vorder);
      setDeviceIsActive(data.data.isActive);
      setDeviceListImage(getImg(data.data.images, "list"));
      setDeviceSellImage(getImg(data.data.images, "sell"));
    } catch (e) {
      console.log(e);
    }
  };

  const modifyDeviceInfo = async () => {
    const formData = new FormData();
    formData.append("title", deviceTitle);
    formData.append("vorder", deviceVorder);
    formData.append("list", deviceListImageData);
    formData.append("sell", deviceSellImageData);
    formData.append("isActive", deviceIsActive);
    try {
      const { data } = await axios.put(
        "/api/device/admin/info/" + id,
        formData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getDeviceInfo();
  }, []);

  function getImg(data, type) {
    const imageArr = data.filter((item) => item.type === type);
    if (imageArr.length) {
      return imageArr[0].url;
    }
    return null;
  }
  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">디바이스 상세보기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div className="mb-3">
            <label htmlFor="deviceIdInput" className="form-label">
              id
            </label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              id="deviceIdInput"
              value={deviceId}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="deviceCreatedDateInput" className="form-label">
              생성일
            </label>
            <input
              value={deviceCreatedDate}
              disabled={true}
              type="text"
              className="form-control"
              id="deviceCreatedDateInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="deviceUpdatedDateInput" className="form-label">
              최근 업데이트
            </label>
            <input
              value={deviceUpdatedDate}
              disabled={true}
              type="text"
              className="form-control"
              id="deviceUpdatedDateInput"
            />
          </div>

          <hr />
          <div className="mb-3">
            <label htmlFor="deviceNameInput" className="form-label">
              디바이스 이름
            </label>
            <input
              defaultValue={deviceTitle}
              type="text"
              className="form-control"
              id="deviceNameInput"
              required={true}
              onChange={(event) => setDeviceTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="deviceVorderInput" className="form-label">
              노출 순서
            </label>
            <input
              min={0}
              onChange={(event) => setDeviceVorder(event.target.value)}
              value={deviceVorder}
              required={true}
              type="number"
              className="form-control"
              id="deviceVorderInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="deviceIsActiveInput" className="form-label">
              공개여부
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={deviceIsActive}
              onChange={(event) => setDeviceIsActive(event.target.value)}
            >
              <option value={true}>공개</option>
              <option value={false}>비공개</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              목록이미지
            </label>
            <img
              className={"d-block img-thumbnail mb-3"}
              style={{ height: "200px" }}
              src={deviceListImage}
            />
            <input
              onChange={(event) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = async function (e) {
                  setDeviceListImage(e.target.result.toString());
                  await console.log(deviceListImage);
                };
                setDeviceListImageData(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              id="formFile"
              accept="image/png, image/jpeg"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              판매이미지
            </label>
            <img
              className={"d-block img-thumbnail mb-3"}
              style={{ height: "200px" }}
              src={deviceSellImage}
            />
            <input
              onChange={(event) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = function (e) {
                  setDeviceSellImage(e.target.result.toString());
                };
                setDeviceSellImageData(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              id="formFile"
              accept="image/png, image/jpeg"
            />
          </div>
          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button
            className="btn btn-primary px-4"
            type={"submit"}
            data-bs-toggle="modal"
            data-bs-target="#save"
            onClick={() => console.log(deviceIsActive)}
          >
            저장
          </button>
        </form>
      </main>
      <Modal
        id={"save"}
        title={"저장하기"}
        subTitle={
          deviceIsActive.toString() === "true"
            ? "해당 디바이스를 노출하면 하위 브랜드들 중 노출 상태인 것이 함께 노출됩니다. 진행하시겠습니까?"
            : "해당 디바이스를 고객 화면에서 미노출 시키겠습니까?"
        }
        confirm={modifyDeviceInfo}
        confirmText={"저장하기"}
        closeText={"취소"}
      />
    </>
  );
};

export default DeviceDetail;
