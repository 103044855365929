import React, { useEffect, useState } from "react";
import { Header, Navbar } from "../../components";
import axios from "axios";
import moment from "moment";
import QueryString from "qs";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
  withSearchParams,
} from "react-router-dom";

const VersionList = () => {
  // 네비바 구성
  const route = [
    { name: "기본정보", link: "/management/info" },
    { name: "통계", link: "/management/stats" },
    { name: "약관", link: "/management/policyList" },
    { name: "정책", link: "tou" },
    { name: "버전", link: "/management/versionList" },
    { name: "제안사항", link: "/management/suggest" },
  ];

  // react-router v6 사용
  const navigate = useNavigate();
  const location = useLocation();

  // 드롭다운 요소
  const [adminGradeList, setAdminGradeList] = useState([]);
  const [adminStateList, setAdminStateList] = useState([]);

  // 쿼리스트링 변수 선언
  const [searchParams, setSearchParams] = useSearchParams();

  // 어드민 검색결과 목록 변수 선언
  const [deviceList, setDeviceList] = useState({});

  // 페이지네이션 갯수 변수 선언
  const [paginationCount, setPaginationCount] = useState(0);

  // 어드민 목록 api 요청
  const getAdminList = async (take, page) => {
    try {
      const { data } = await axios.get(
        "/api/company/version?take=" + take + "&page=" + page
      );
      console.log(data.data);
      setDeviceList(data.data);
      setPaginationCount(Math.ceil(data.data.total / searchParams.get("take")));
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 옵션 목록 호출
  // 페이지네이션 함수
  const setPagination = (pageNumber) => {
    searchParams.set("page", pageNumber);
    setSearchParams(searchParams);
  };

  // 페이지 첫 로딩시 take, page 확인해서 강제 리다이랙팅
  useEffect(() => {
    if (
      searchParams.get("take") === null ||
      searchParams.get("page") === null
    ) {
      navigate("?take=" + 10 + "&page=" + 1);
    } else {
      getAdminList(searchParams.get("take"), searchParams.get("page"));
    }
  }, [location.search]);

  // 뷰
  return (
    <>
      <Header />
      <Navbar menu={route} />
      <main className={"container"}>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2 fw-bold">버전 목록</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            {/*<div className="btn-group me-2">*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    className="btn btn-sm btn-outline-secondary"*/}
            {/*  >*/}
            {/*    엑셀 내보내기*/}
            {/*  </button>*/}
            {/*</div>*/}
            <select
              className="form-select form-select-sm"
              value={searchParams.get("take")}
              onChange={(event) => {
                searchParams.set("take", event.target.value);
                searchParams.set("page", 1);
                setSearchParams(searchParams);
              }}
            >
              <option key={0} value={10}>
                10개씩 보기
              </option>
              <option key={1} value={30}>
                30개씩 보기
              </option>
              <option key={3} value={50}>
                50개씩 보기
              </option>
              <option key={4} value={100}>
                100개씩 보기
              </option>
            </select>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered caption-top text-nowrap m-0 align-middle">
            <caption className={"text-dark"}>
              <div className={"d-flex justify-content-between"}>
                <span>
                  검색결과 :{" "}
                  <span className={"text-danger fw-bold"}>
                    {deviceList.total}
                  </span>
                  개
                </span>
                <div className="">
                  <Link
                    type="button"
                    className="btn btn-sm btn-primary"
                    to={"/management/versionNew"}
                  >
                    + 버전등록
                  </Link>
                </div>
              </div>
            </caption>
            <thead>
              <tr>
                <th>No</th>
                <th>버전명</th>
                <th>업데이트 내역</th>
                <th>업데이트 필수 여부</th>
                <th>적용일자</th>
              </tr>
            </thead>
            <tbody>
              {deviceList.results?.length === 0 ? (
                <>
                  <tr>
                    <td className={"text-center"} colSpan={100}>
                      검색결과가 없습니다.
                    </td>
                  </tr>
                </>
              ) : (
                deviceList.results?.map((i, index) => (
                  <tr key={index}>
                    <td>
                      {(searchParams.get("page") - 1) *
                        searchParams.get("take") +
                        index +
                        1}
                    </td>
                    <td>
                      <Link to={"/management/versionDetail/" + i.id}>
                        {i.title}
                      </Link>
                    </td>
                    <td>{i.log}</td>
                    <td>{i.isEssential ? "Y" : "N"}</td>
                    <td>{moment(i.activeDate).format("YYYY-MM-DD")}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {/* 태이블 끝 */}
        <nav aria-label="Page navigation example" className={"mt-4"}>
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <button
                className="page-link text-dark"
                onClick={() =>
                  setPagination(Number(searchParams.get("page")) - 1)
                }
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {[...Array(paginationCount)].map((i, index) => (
              <li className="page-item" key={index + 1}>
                <button
                  className={
                    Number(searchParams.get("page")) === index + 1
                      ? "page-link fw-bold"
                      : "page-link text-muted"
                  }
                  onClick={() => setPagination(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className="page-link text-dark"
                onClick={() =>
                  setPagination(Number(searchParams.get("page")) + 1)
                }
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </main>
    </>
  );
};

export default VersionList;
