import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const SeriesDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  // 편집 불가능한 요소
  const [seriesId, setSeriesId] = useState("");
  const [seriesCreatedDate, setSeriesCreatedDate] = useState("");
  const [seriesUpdatedDate, setSeriesUpdatedDate] = useState("");

  // 편집 가능한 요소
  const [seriesTitle, setSeriesTitle] = useState("");
  const [seriesVorder, setSeriesVorder] = useState(0);
  const [seriesIsActive, setSeriesIsActive] = useState(true);
  const [seriesDevice, setSeriesDevice] = useState(0);
  const [seriesBrand, setSeriesBrand] = useState(0);

  // 드롭다운 요소
  const [deviceList, setDeviceList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  // 어드민 정보 호출
  const getSeriesInfo = async () => {
    try {
      const { data } = await axios.get("/api/series/admin/info/" + id);
      setSeriesId(data.data.id);
      setSeriesCreatedDate(moment(data.data.createdAt).format("YYYY-MM-DD"));
      setSeriesUpdatedDate(
        moment(data.data.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      );
      setSeriesTitle(data.data.title);
      setSeriesVorder(data.data.vorder);
      setSeriesIsActive(data.data.isActive);
      setSeriesDevice(data.data.device.id);
      setSeriesBrand(data.data.brand.id);
      getBrandList(data.data.device.id);
    } catch (e) {
      console.log(e);
    }
  };

  const getDeviceList = async () => {
    try {
      const { data } = await axios.get("/api/series/admin/list/filter/device");
      if (data.statusCode === 200) {
        setDeviceList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBrandList = async (deviceId) => {
    try {
      const { data } = await axios.get(
        "/api/series/admin/list/filter/brand/?deviceId=" + deviceId
      );
      if (data.statusCode === 200) {
        setBrandList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const modifySeriesInfo = async () => {
    console.log(seriesBrand);
    if (!seriesBrand) {
      alert("브랜드를 선택해주세요");
      return false;
    }
    try {
      const { data } = await axios.put(
        "/api/series/admin/info/" + id,
        JSON.stringify({
          title: seriesTitle.toString(),
          vorder: seriesVorder.toString(),
          deviceId: seriesDevice.toString(),
          brandId: seriesBrand.toString(),
          isActive: seriesIsActive.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getSeriesInfo();
    getDeviceList();
  }, []);

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">시리즈 상세보기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div className="mb-3">
            <label htmlFor="seriesIdInput" className="form-label">
              id
            </label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              id="seriesIdInput"
              value={seriesId}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="seriesCreatedDateInput" className="form-label">
              생성일
            </label>
            <input
              value={seriesCreatedDate}
              disabled={true}
              type="text"
              className="form-control"
              id="seriesCreatedDateInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="seriesUpdatedDateInput" className="form-label">
              최근 업데이트
            </label>
            <input
              value={seriesUpdatedDate}
              disabled={true}
              type="text"
              className="form-control"
              id="seriesUpdatedDateInput"
            />
          </div>

          <hr />
          <div className="mb-3">
            <label htmlFor="seriesDeviceInput" className="form-label">
              디바이스
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={seriesDevice}
              onChange={(event) => {
                getBrandList(event.target.value).then((r) => {
                  setSeriesBrand(0);
                });
                setSeriesDevice(event.target.value);
              }}
            >
              {deviceList.map((i, index) => (
                <option key={index} value={i.id}>
                  {i.title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="seriesBrandInput" className="form-label">
              브랜드
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={seriesBrand}
              disabled={brandList.length === 0}
              onChange={(event) => {
                setSeriesBrand(event.target.value);
              }}
            >
              {brandList.length === 0 ? (
                <option key={0} value={null}>
                  선택할 수 없습니다
                </option>
              ) : (
                <>
                  <option hidden={true} value={null}>
                    브랜드를 선택해주세요
                  </option>
                  {brandList.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.title}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="seriesNameInput" className="form-label">
              시리즈 이름
            </label>
            <input
              defaultValue={seriesTitle}
              type="text"
              className="form-control"
              id="seriesNameInput"
              required={true}
              onChange={(event) => setSeriesTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="seriesVorderInput" className="form-label">
              노출 순서
            </label>
            <input
              min={0}
              onChange={(event) => setSeriesVorder(event.target.value)}
              value={seriesVorder}
              required={true}
              type="number"
              className="form-control"
              id="seriesVorderInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="seriesIsActiveInput" className="form-label">
              노출 여부
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={seriesIsActive}
              onChange={(event) => setSeriesIsActive(event.target.value)}
            >
              <option value={true}>노출</option>
              <option value={false}>미노출</option>
            </select>
          </div>

          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button
            className="btn btn-primary px-4"
            type={"submit"}
            data-bs-toggle="modal"
            data-bs-target="#save"
          >
            저장
          </button>
        </form>
      </main>
      <Modal
        id={"save"}
        title={"저장하기"}
        subTitle={
          seriesIsActive.toString() === "true"
            ? "해당 시리즈를 고객 화면에서 노출 시키겠습니까?"
            : "해당 시리즈를 고객 화면에서 미노출 시키겠습니까?"
        }
        confirm={modifySeriesInfo}
        confirmText={"저장하기"}
        closeText={"취소"}
      />
    </>
  );
};

export default SeriesDetail;
