import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const SheetNew = () => {
  let navigate = useNavigate();

  // 편집 가능한 요소
  const [newsheet, setNewSheet] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [uploadBtnTitle, setUploadBtnTitle] = useState("단가표 업로드");

  const [uploadCheck1, setUploadCheck1] = useState("dark");
  const [uploadCheck1Value, setUploadCheck1Value] = useState([]);

  const [uploadCheck2, setUploadCheck2] = useState("dark");
  const [uploadCheck2Value, setUploadCheck2Value] = useState([]);

  const [uploadCheck3, setUploadCheck3] = useState("dark");
  const [uploadCheck3Value, setUploadCheck3Value] = useState([]);

  const [uploadCheck4, setUploadCheck4] = useState("dark");
  const [uploadCheck4Value, setUploadCheck4Value] = useState([]);
  // 어드민 정보 호출

  useEffect(() => {}, []);

  const uploadSheet = async () => {
    const formData = new FormData();
    formData.append("file", newsheet);
    try {
      setIsUpload(true);
      setUploadBtnTitle("업로드 중...");
      const { data } = await axios.post(
        "/api/checklist/admin/list/excel",
        formData,
        { headers: { "content-type": "multipart/form-data" } }
      );
      if (data.statusCode == 200) {
        setUploadBtnTitle("업로드 완료");
        alert("정상적으로 업로드 되었습니다");
      } else {
        alert("업로드 오류");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">단가표 등록</h1>
        </div>
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          />
          <h5>단가표 등록방법(필독)</h5>
          <hr />
          1. 단가표 엑셀을 다운로드 한뒤에, 값을 채워넣고 업로드 및 단가표
          저장해주시면 됩니다.
          <br />
          <br />
          2. 설문지가 등록되지 않은 기기는 엑셀 다운로드에 포함되지 않습니다.
          <br />
          (단가표 등록을 원하시면 설문지 먼저 매핑해주세요.)
          <br />
          <br />
          3. 엑셀 행을 “절대” 임의 삭제하지 마세요.
          <br />
          (답변/설문을 바꾸고 싶으면, [Q&A 등록 -> 설문 등록 -> 설문 매핑]
          순으로 진행해주세요)
          <br />
          <br />
          4. 가격업데이트가 필요없는 기종에 대해서는 이전값과 같은 가격을
          복사해서 넣어주세요.
          <br />
          (신규가격을 공백으로 놔두면 오류가 발생합니다. 무조건 채워주세요.)
        </div>
        <div className="mb-3">
          <label className="form-label">단가표 양식</label>
          <div>
            <button
              className={"btn btn-primary"}
              onClick={async () => {
                try {
                  const response = await axios.get(
                    "/api/checklist/admin/list/excel",
                    { responseType: "blob" }
                  );
                  const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                      type: response.headers["content-type"],
                    })
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "priceLists");
                  document.body.appendChild(link);
                  link.click();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              다운로드
            </button>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">신규 단가표</label>
          <div className={"input-group"}>
            <input
              disabled={isUpload}
              onChange={(event) => setNewSheet(event.target.files[0])}
              className="form-control"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            {newsheet ? (
              <button
                disabled={isUpload}
                className={"btn btn-dark px-5"}
                data-bs-toggle="modal"
                data-bs-target="#upload"
              >
                {uploadBtnTitle}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <hr />
        {uploadBtnTitle == "업로드 완료" ? (
          <>
            <div className={"mb-3"}>
              <h5>업로드 파일 검증하기</h5>
            </div>
            <div className="mb-3">
              <div
                className={
                  "alert d-flex justify-content-between align-items-center alert-" +
                  uploadCheck1
                }
              >
                <div className={"col"}>
                  <strong>검증 1</strong> : 프로덕마다 가격이 전체 입력되어
                  있는가? (일부 값만 입력하는 것 불가능함. 다 입력하던지, 일부만
                  입력하던지 해야함.)
                </div>
                {uploadCheck1 === "danger" ? (
                  <button
                    className={"btn btn-outline-danger"}
                    data-bs-toggle="modal"
                    data-bs-target="#uploadList1"
                  >
                    오류 확인
                  </button>
                ) : (
                  <button
                    className={"btn btn-" + uploadCheck1}
                    onClick={async () => {
                      try {
                        const { data } = await axios.get(
                          "/api/checklist/admin/list/excel/validate?validationType=is_null",
                          { headers: { "content-type": "application/json" } }
                        );
                        if (data.data.length === 0) {
                          setUploadCheck1("success");
                        } else {
                          setUploadCheck1("danger");
                          setUploadCheck1Value(data.data);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  >
                    {uploadCheck1 === "success" ? "검증완료" : "검증하기"}
                  </button>
                )}
              </div>
            </div>
            <div className="mb-3">
              <div
                className={
                  "alert d-flex justify-content-between align-items-center alert-" +
                  uploadCheck2
                }
              >
                <div className={"col"}>
                  <strong>검증 2</strong> : 입력된 가격 정보중에 음수값이
                  존재하는가? (없어야함)
                </div>
                {uploadCheck2 === "danger" ? (
                  <button
                    className={"btn btn-outline-danger"}
                    data-bs-toggle="modal"
                    data-bs-target="#uploadList2"
                  >
                    오류 확인
                  </button>
                ) : (
                  <button
                    className={"btn btn-" + uploadCheck2}
                    onClick={async () => {
                      try {
                        const { data } = await axios.get(
                          "/api/checklist/admin/list/excel/validate?validationType=is_minus",
                          { headers: { "content-type": "application/json" } }
                        );
                        if (data.data.length === 0) {
                          setUploadCheck2("success");
                        } else {
                          setUploadCheck2("danger");
                          setUploadCheck2Value(data.data);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  >
                    {uploadCheck2 === "success" ? "검증완료" : "검증하기"}
                  </button>
                )}
              </div>
            </div>
            <div className="mb-3">
              <div
                className={
                  "alert d-flex justify-content-between align-items-center alert-" +
                  uploadCheck3
                }
              >
                <div className={"col"}>
                  <strong>검증 3</strong> : change_finish 값이 1000원 미만인
                  것이 있는가? (없어야함)
                </div>
                {uploadCheck3 === "danger" ? (
                  <button
                    className={"btn btn-outline-danger"}
                    data-bs-toggle="modal"
                    data-bs-target="#uploadList3"
                  >
                    오류 확인
                  </button>
                ) : (
                  <button
                    className={"btn btn-" + uploadCheck3}
                    onClick={async () => {
                      try {
                        const { data } = await axios.get(
                          "/api/checklist/admin/list/excel/validate?validationType=under_1000",
                          { headers: { "content-type": "application/json" } }
                        );
                        if (data.data.length === 0) {
                          setUploadCheck3("success");
                        } else {
                          setUploadCheck3("danger");
                          setUploadCheck3Value(data.data);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  >
                    {uploadCheck3 === "success" ? "검증완료" : "검증하기"}
                  </button>
                )}
              </div>
            </div>
            <div className="mb-3">
              <div
                className={
                  "alert d-flex justify-content-between align-items-center alert-" +
                  uploadCheck4
                }
              >
                <div className={"col"}>
                  <strong>검증 4</strong> : minus_next 값이 30만원 이상인 것이
                  있는가? (없어야함)
                </div>

                {uploadCheck4 === "danger" ? (
                  <button
                    className={"btn btn-outline-danger"}
                    data-bs-toggle="modal"
                    data-bs-target="#uploadList4"
                  >
                    오류 확인
                  </button>
                ) : (
                  <button
                    className={"btn btn-" + uploadCheck4}
                    onClick={async () => {
                      try {
                        const { data } = await axios.get(
                          "/api/checklist/admin/list/excel/validate?validationType=over_300000",
                          { headers: { "content-type": "application/json" } }
                        );
                        if (data.data.length === 0) {
                          setUploadCheck4("success");
                        } else {
                          setUploadCheck4("danger");
                          setUploadCheck4Value(data.data);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  >
                    {uploadCheck4 === "success" ? "검증완료" : "검증하기"}
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <Link className="btn btn-outline-secondary me-3 px-4" to={-1}>
          목록
        </Link>
        <button
          disabled={
            uploadCheck1 !== "success" ||
            uploadCheck2 !== "success" ||
            uploadCheck3 !== "success"
          }
          className="btn btn-primary px-4"
          onClick={async () => {
            try {
              const { data } = await axios.put(
                "/api/checklist/admin/list/excel"
              );
              if (data.statusCode == 200) {
                alert("정상적으로 업로드 되었습니다");
                navigate(-1);
              } else {
                alert("업로드 오류");
              }
            } catch (e) {
              console.log(e);
            }
          }}
        >
          저장
        </button>
      </main>
      <Modal
        id={"upload"}
        title={"단가표 업로드"}
        subTitle={"파일을 업로드 하시겠습니까?"}
        confirm={uploadSheet}
        confirmText={"업로드"}
        closeText={"취소"}
      />
      <Modal
        size={"modal-lg"}
        id={"uploadList1"}
        title={"단가표 확인"}
        subTitle={uploadCheck1Value.map((i, index) => (
          <>
            <div className={"row"}>
              <div className={"col-auto"}>{i.productId}</div>
              <div className={"col-auto"}>{i.answerId}</div>
              <div className={"col-4"}>{i.answerTitle}</div>
              <div className={"col"}>{i.answerType}</div>
              <div className={"col-auto"}>{i.price}</div>
            </div>
            <hr />
          </>
        ))}
        confirmText={"확인"}
      />
      <Modal
        size={"modal-lg"}
        id={"uploadList2"}
        title={"단가표 확인"}
        subTitle={uploadCheck2Value.map((i, index) => (
          <>
            <div className={"row"}>
              <div className={"col-auto"}>{i.productId}</div>
              <div className={"col-auto"}>{i.answerId}</div>
              <div className={"col-4"}>{i.answerTitle}</div>
              <div className={"col"}>{i.answerType}</div>
              <div className={"col-auto"}>{i.price}</div>
            </div>
            <hr />
          </>
        ))}
        confirmText={"확인"}
      />
      <Modal
        size={"modal-lg"}
        id={"uploadList3"}
        title={"단가표 확인"}
        subTitle={uploadCheck3Value.map((i, index) => (
          <>
            <div className={"row"}>
              <div className={"col-auto"}>{i.productId}</div>
              <div className={"col-auto"}>{i.answerId}</div>
              <div className={"col-4"}>{i.answerTitle}</div>
              <div className={"col"}>{i.answerType}</div>
              <div className={"col-auto"}>{i.price}</div>
            </div>
            <hr />
          </>
        ))}
        confirmText={"확인"}
      />
      <Modal
        size={"modal-lg"}
        id={"uploadList4"}
        title={"단가표 확인"}
        subTitle={uploadCheck4Value.map((i, index) => (
          <>
            <div className={"row"}>
              <div className={"col-auto"}>{i.productId}</div>
              <div className={"col-auto"}>{i.answerId}</div>
              <div className={"col-4"}>{i.answerTitle}</div>
              <div className={"col"}>{i.answerType}</div>
              <div className={"col-auto"}>{i.price}</div>
            </div>
            <hr />
          </>
        ))}
        confirmText={"확인"}
      />
    </>
  );
};

export default SheetNew;
