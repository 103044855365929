import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const AdminDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  // 드롭다운 요소
  const [adminGradeList, setAdminGradeList] = useState([]);
  const [adminStateList, setAdminStateList] = useState([]);

  // 편집 불가능한 요소
  const [adminCreatedDate, setAdminCreatedDate] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");

  // 편집 가능한 요소
  const [adminGrade, setAdminGrade] = useState("");
  const [adminState, setAdminState] = useState("");

  // 페이지 옵션 목록 호출
  const getOptionList = async () => {
    try {
      const { data } = await axios.get("/api/user/admin/list/grade");
      if (data.statusCode === 200) {
        setAdminGradeList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/api/user/admin/list/state");
      if (data.statusCode === 200) {
        setAdminStateList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 어드민 정보 호출
  const getAdminInfo = async () => {
    try {
      const { data } = await axios.get("/api/user/admin/info/" + id);
      if (data.statusCode === 401) {
        alert("수정권한이 없습니다.");
        navigate(-1);
      } else {
        setUserName(data.data.userName);
        setUserId(data.data.userId);
        setPhone(data.data.phone);
        setEmail(data.data.email);
        setNickname(data.data.admin.nickName);
        setAdminState(data.data.admin.adminState);
        setAdminGrade(data.data.admin.grade);
        setAdminCreatedDate(
          moment(data.data.admin.createdAt).format("YYYY-MM-DD")
        );
      }
    } catch (e) {
      console.log(e);
      navigate("/");
    }
  };
  const modifyAdminInfo = async () => {
    try {
      const { data } = await axios.put(
        "/api/user/admin/info/" + id,
        JSON.stringify({
          grade: Number(adminGrade),
          adminState: adminState.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getOptionList();
    getAdminInfo();
  }, []);

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">관리자 상세보기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div className="mb-3">
            <label htmlFor="adminCreatedDateInput" className="form-label">
              가입일
            </label>
            <input
              value={adminCreatedDate}
              disabled={true}
              type="text"
              className="form-control"
              id="adminCreatedDateInput"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <label htmlFor="adminNameInput" className="form-label">
              이름
            </label>
            <input
              disabled={true}
              pattern="[가-힣]+"
              required={true}
              maxLength={6}
              type="text"
              className="form-control"
              id="adminNameInput"
              placeholder="이기톨"
              value={userName}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="adminIdInput" className="form-label">
              아이디
            </label>
            <input
              disabled={true}
              pattern="^[a-zA-Z0-9]+$"
              required={true}
              type="text"
              className="form-control"
              id="adminIdInput"
              placeholder="gittle"
              value={userId}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="adminPhoneInput" className="form-label">
              휴대폰 번호
            </label>
            <input
              disabled={true}
              value={phone}
              pattern="\d{11}"
              maxLength={11}
              required={true}
              type="tel"
              className="form-control"
              id="adminPhoneInput"
              placeholder="010xxxxxxxx"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="adminNickNameInput" className="form-label">
              별명
            </label>
            <input
              disabled={true}
              required={true}
              type="text"
              className="form-control"
              id="adminNickNameInput"
              placeholder="gittle"
              value={nickname}
              onChange={(event) => setNickname(event.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="adminEmailInput" className="form-label">
              이메일 주소
            </label>
            <input
              value={email}
              disabled={true}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              required={true}
              type="email"
              className="form-control"
              id="adminEmailInput"
              placeholder="help@21market.kr"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>

          <hr />
          <div className="mb-3">
            <label htmlFor="adminGradeInput" className="form-label">
              관리자 레벨
            </label>
            <select
              className="form-select"
              value={adminGrade}
              onChange={(event) => setAdminGrade(event.target.value)}
            >
              {adminGradeList.map((i, index) => (
                <option key={index} value={i.value}>
                  {i.key}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="adminStateInput" className="form-label">
              관리자 상태
            </label>
            <select
              className="form-select"
              value={adminState}
              onChange={(event) => setAdminState(event.target.value)}
            >
              {adminStateList.map((i, index) => (
                <option key={index} value={i.value}>
                  {i.key}
                </option>
              ))}
            </select>
          </div>
          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button
            className="btn btn-primary px-4"
            type={"submit"}
            data-bs-toggle="modal"
            data-bs-target="#save"
          >
            저장
          </button>
        </form>
      </main>
      <Modal
        id={"save"}
        title={"저장하기"}
        subTitle={"현재 상태를 저장하시겠습니까?"}
        confirm={modifyAdminInfo}
        confirmText={"저장하기"}
        closeText={"취소"}
      />
    </>
  );
};

export default AdminDetail;
