import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const SeriesNew = () => {
  let navigate = useNavigate();

  // 편집 가능한 요소
  const [seriesTitle, setSeriesTitle] = useState("");
  const [seriesVorder, setSeriesVorder] = useState(0);
  const [seriesIsActive, setSeriesIsActive] = useState(true);
  const [seriesDevice, setSeriesDevice] = useState(0);
  const [seriesBrand, setSeriesBrand] = useState(0);

  // 드롭다운 요소
  const [deviceList, setDeviceList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const modifySeriesInfo = async () => {
    try {
      const { data } = await axios.post(
        "/api/series/admin/info/",
        JSON.stringify({
          title: seriesTitle.toString(),
          vorder: seriesVorder.toString(),
          deviceId: seriesDevice.toString(),
          brandId: seriesBrand.toString(),
          isActive: seriesIsActive.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 201) {
        alert("정보수정이 완료되었습니다");
        navigate(-1);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };
  const getDeviceList = async () => {
    try {
      const { data } = await axios.get("/api/series/admin/list/filter/device");
      if (data.statusCode === 200) {
        setDeviceList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBrandList = async (deviceId) => {
    try {
      const { data } = await axios.get(
        "/api/series/admin/list/filter/brand/?deviceId=" + deviceId
      );
      if (data.statusCode === 200) {
        setBrandList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getDeviceList();
  }, []);

  function getImg(data, type) {
    const imageArr = data.filter((item) => item.type === type);
    if (imageArr.length) {
      return imageArr[0].url;
    }
    return null;
  }
  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">시리즈 생성하기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (!seriesBrand) {
              alert("브랜드를 선택해주세요");
              return false;
            }
            modifySeriesInfo();
          }}
        >
          <div className="mb-3">
            <label htmlFor="seriesDeviceInput" className="form-label">
              디바이스
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={seriesDevice}
              onChange={(event) => {
                getBrandList(event.target.value).then((r) => {
                  setSeriesBrand(brandList[0]);
                  console.log(seriesBrand);
                });
                setSeriesDevice(event.target.value);
              }}
            >
              <option hidden={true}>디바이스를 선택해주세요</option>
              {deviceList.map((i, index) => (
                <option key={index} value={i.id}>
                  {i.title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="seriesBrandInput" className="form-label">
              브랜드
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={seriesBrand}
              disabled={brandList.length === 0}
              onChange={(event) => setSeriesBrand(event.target.value)}
            >
              {brandList.length === 0 ? (
                <option key={0} value={null}>
                  선택할 수 없습니다
                </option>
              ) : (
                <>
                  <option hidden={true} value={null}>
                    브랜드를 선택해주세요
                  </option>
                  {brandList.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.title}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="seriesNameInput" className="form-label">
              시리즈 이름
            </label>
            <input
              placeholder={"핸드폰"}
              defaultValue={seriesTitle}
              type="text"
              className="form-control"
              id="seriesNameInput"
              required={true}
              onChange={(event) => setSeriesTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="seriesVorderInput" className="form-label">
              노출 순서
            </label>
            <input
              min={0}
              onChange={(event) => setSeriesVorder(event.target.value)}
              value={seriesVorder}
              required={true}
              type="number"
              className="form-control"
              id="seriesVorderInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="seriesIsActiveInput" className="form-label">
              노출여부
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={seriesIsActive}
              onChange={(event) => setSeriesIsActive(event.target.value)}
            >
              <option value={true}>노출</option>
              <option value={false}>미노출</option>
            </select>
          </div>

          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default SeriesNew;
