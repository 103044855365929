import React, { useEffect, useState } from "react";
import { Header, Navbar } from "../../components";
import axios from "axios";
import moment from "moment";
import QueryString from "qs";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
  withSearchParams,
} from "react-router-dom";

const SheetList = () => {
  // 네비바 구성
  const route = [
    { name: "Q&A", link: "/query/qnaList" },
    { name: "설문지", link: "/query/formList" },
    { name: "설문 매핑", link: "/query/mappingList" },
    { name: "단가표", link: "/query/sheetList" },
  ];
  const [deviceList, setDeviceList] = useState([]);
  const [pricedYn, setPricedYn] = useState(false);

  const getAdminList = async (pricedYn) => {
    setDeviceList([]);
    try {
      const { data } = await axios.get(
        "/api/checklist/admin/list/price?pricedYn=" + pricedYn
      );
      setDeviceList(data.data);

      console.log(deviceList);
    } catch (e) {
      console.log(e);
    }
  };
  // react-router v6 사용
  const navigate = useNavigate();

  // 페이지 첫 로딩시 take, page 확인해서 강제 리다이랙팅
  useEffect(() => {
    getAdminList(pricedYn);
  }, []);

  // 뷰
  return (
    <>
      <Header />
      <Navbar menu={route} />
      <main className={"container"}>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2 fw-bold">단가표 목록</h1>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered caption-top text-nowrap m-0 align-middle">
            <caption className={"text-dark"}>
              <div className={"d-flex justify-content-between"}>
                <div>
                  <div className="form-check form-check-inline ms-1 ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={pricedYn}
                      onChange={(event) => getAdminList(event.target.checked)}
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      가격표 등록된 상품만 보기
                    </label>
                  </div>
                  <span>
                    검색결과 :{" "}
                    <span className={"text-danger fw-bold"}>
                      {deviceList.length}
                    </span>
                    개
                  </span>
                </div>
                <div className="">
                  <Link
                    type="button"
                    className="btn btn-sm btn-primary"
                    to={"/query/sheetNew"}
                  >
                    + 단가표 업로드
                  </Link>
                </div>
              </div>
            </caption>
            <thead>
              <tr>
                <th>No</th>
                <th>폰 id</th>
                <th>기기 브랜드</th>
                <th>기기 시리즈</th>
                <th>상세기종</th>
                <th>사용중 설문</th>
                <th>가격표 등록일</th>
              </tr>
            </thead>
            <tbody>
              {deviceList.length === 0 ? (
                <>
                  <tr>
                    <td className={"text-center py-5"} colSpan={100}>
                      검색결과를 로딩중입니다.
                    </td>
                  </tr>
                </>
              ) : (
                deviceList.map((i, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.productId}</td>
                    <td>{i.brandTitle}</td>
                    <td>{i.seriesTitle}</td>
                    <td>{i.productTitle}</td>
                    <td>
                      {i.checklistTitle ? (
                        i.checklistTitle
                      ) : (
                        <span className={"text-danger"}>미등록</span>
                      )}
                    </td>
                    <td>
                      {i.productPriceCreatedAt ? (
                        i.productPriceCreatedAt
                      ) : (
                        <span className={"text-danger"}>미등록</span>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {/* 태이블 끝 */}
      </main>
    </>
  );
};

export default SheetList;
