import React, { useEffect, useState } from "react";
import { Header, Navbar } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Info = () => {
  let navigate = useNavigate();
  const route = [
    { name: "기본정보", link: "/management/info" },
    { name: "통계", link: "/management/stats" },
    { name: "약관", link: "/management/policyList" },
    { name: "정책", link: "tou" },
    { name: "버전", link: "/management/versionList" },
    { name: "제안사항", link: "/management/suggest" },
  ];

  const nameList = {
    id: "아이디 (수정불가)",
    title: "회사이름 (수정불가)",
    addr: "주소",
    postCode: "우편번호",
    ceoName: "대표자",
    serviceName: "서비스이름",
    bizNumber: "사업자등록번호",
    mailOrderBiz: "통신판매업등록번호",
    contactNum1: "전화번호 1",
    contactNum2: "전화번호 2",
    fax: "팩스번호",
    copyRight: "카피라이트",
    contactEmail: "문의 이메일",
    bizEmail: "제휴 이메일",
    blog: "블로그",
    facebookLink: "페이스북",
    instagramLink: "인스타그램",
    kakaoChanelLink: "카카오채널",
    playStoreLink: "플레이스토어",
    appStoreLink: "앱스토어",
    catchPhrase: "캐치프레이즈",
    eventTargetUrl: "대상자 설문링크",
    notEventTargetUrl: "미대상자 설문링크",
    eventDetail: "이벤트 내용",
  };
  const [companyInfo, setCompanyInfo] = useState({});
  const [reminder, setReminder] = useState([]);
  const getCompanyInfo = async () => {
    try {
      const { data } = await axios.get("/api/company/info");
      if (data.statusCode === 200) {
        setCompanyInfo(data.data);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/api/company/reminder/policy");
      if (data.statusCode === 200) {
        setReminder(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const modifyCompanyInfo = async (value) => {
    try {
      const { data } = await axios.patch("/api/company/info", value);
      if (data.statusCode === 200) {
        console.log("완료");
        alert("정상적으로 수정되었습니다");
        navigate(0);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCompanyInfo();
  }, []);
  return (
    <>
      <Header />
      <Navbar menu={route} />

      <main className="container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2 fw-bold">기본정보</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const data = new FormData(event.target);

            const value = Object.fromEntries(data.entries());
            modifyCompanyInfo(value);
          }}
        >
          <div>
            {Object.keys(companyInfo).map((key, i) => (
              <div className="mb-3" key={i}>
                <label className="form-label">
                  {nameList[key] ? nameList[key] : key}
                </label>
                <input
                  disabled={
                    key === "id" ||
                    key === "title" ||
                    key === "createdAt" ||
                    key === "updatedAt"
                  }
                  name={key}
                  type="text"
                  className="form-control"
                  defaultValue={companyInfo[key]}
                />
              </div>
            ))}
          </div>
          <div className="alert alert-dark" role="alert">
            <h5>서버 스케줄 항목</h5>
            {reminder.map((i, index) => (
              <p className={"m-0"}>{i.value}</p>
            ))}
            <hr />
            *변경이 필요할 시 개발팀 문의
          </div>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default Info;
