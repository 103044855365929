import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const FormNew = () => {
  let navigate = useNavigate();

  // 편집 가능한 요소
  const [formTitle, setFormTitle] = useState("");
  const [formShowYn, setFormShowYn] = useState(true);
  const [formDevice, setFormDevice] = useState(1);

  const [deviceList, setDeviceList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [questionId, setQuestionId] = useState(1);
  const [questionTitle, setQuestionTitle] = useState("");

  const [qnaList, setQnaList] = useState([]);
  // 어드민 정보 호출

  const getOption = async () => {
    try {
      const { data } = await axios.get(
        "/api/checklist/admin/list/filter/device",
        {
          headers: { "content-type": "application/json" },
        }
      );
      setDeviceList(data.data);
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
    try {
      const { data } = await axios.get(
        "/api/checklist/admin/list/filter/question",
        {
          headers: { "content-type": "application/json" },
        }
      );
      setQuestionList(data.data);
      setQuestionTitle(data.data[0].title);
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  const createForm = async () => {
    if (qnaList.length < 1) {
      alert("1개 이상 질문을 추가하세요");
      return false;
    }
    const set = new Set(qnaList.map((qna) => qna.id));
    if (set.size < qnaList.map((qna) => qna.id).length) {
      alert("중복된 질문이 있습니다.");
      return false;
    }
    try {
      const { data } = await axios.post(
        "/api/checklist/admin/info",
        JSON.stringify({
          deviceId: formDevice,
          title: formTitle.toString(),
          questionIdArr: qnaList.map((qna) => qna.id).toString(),
          showYn: formShowYn,
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 201) {
        alert(
          "설문이 저장되었습니다.\n실제 적용을 원하시면, [설문지 매핑]에서 기기와매핑해주세요."
        );
        navigate(-1);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getOption();
  }, []);

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">설문지 생성</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            createForm();
          }}
        >
          <div className="mb-3">
            <label className="form-label">설문세트명</label>
            <input
              placeholder={"갤럭시 설문 세트"}
              defaultValue={formTitle}
              type="text"
              className="form-control"
              required={true}
              onChange={(event) => setFormTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">공개여부</label>
            <select
              className="form-select"
              value={formShowYn}
              onChange={(event) => setFormShowYn(event.target.value)}
            >
              <option value={true}>공개</option>
              <option value={false}>비공개</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">디바이스</label>
            <select
              className="form-select"
              value={formDevice}
              onChange={(event) => setFormDevice(event.target.value)}
            >
              {deviceList.map((i, index) => (
                <option key={index} value={i.id}>
                  {i.title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">
              질문등록
              <span className={"text-danger badge"}>
                * 질문등록 순서는 질문순서와 동일합니다. 신경써주세용
              </span>
            </label>
            <div className={"input-group"}>
              <select
                className="form-select"
                onChange={(event) => {
                  setQuestionId(event.target.value);
                  setQuestionTitle(
                    event.target.options[event.target.selectedIndex].text
                  );
                }}
              >
                {questionList.map((i, index) => (
                  <option key={index} value={i.id}>
                    {i.title}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-primary px-4"
                type="button"
                onClick={() => {
                  setQnaList([
                    ...qnaList,
                    { id: questionId, title: questionTitle },
                  ]);
                }}
              >
                질문 추가
              </button>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">질문모음집</label>
            <table className="table table-striped table-bordered m-0 align-middle">
              <tbody>
                {qnaList.length === 0 ? (
                  <>
                    <tr>
                      <td className={"text-center py-5"} colSpan={100}>
                        추가된 질문이 없습니다
                      </td>
                    </tr>
                  </>
                ) : (
                  qnaList.map((i, index) => (
                    <tr key={index}>
                      <td className={"col-1"}>순서 {index + 1}</td>
                      <td>{i.title}</td>
                      <td className={"col-1"}>
                        <button
                          type={"button"}
                          className={"btn btn-outline-primary w-100"}
                          onClick={() => {
                            qnaList.splice(index, 1);
                            setQnaList([...qnaList]);
                          }}
                        >
                          삭제
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"button"}
            to={-1}
          >
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default FormNew;
