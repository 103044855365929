import React, { useEffect, useState } from "react";
import { Header, Navbar } from "../../components";
import axios from "axios";
import moment from "moment";
import QueryString from "qs";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
  withSearchParams,
} from "react-router-dom";

const AdminList = () => {
  // 네비바 구성
  const route = [
    { name: "유저", link: "/account/userList" },
    { name: "관리자", link: "/account/adminList" },
  ];

  // react-router v6 사용
  const navigate = useNavigate();
  const location = useLocation();

  // 드롭다운 요소
  const [gradeList, setgradeList] = useState([]);
  const [adminStateList, setAdminStateList] = useState([]);

  // 쿼리스트링 변수 선언
  const [searchParams, setSearchParams] = useSearchParams();

  // 어드민 검색결과 목록 변수 선언
  const [adminList, setAdminList] = useState({});

  // 페이지네이션 갯수 변수 선언
  const [paginationCount, setPaginationCount] = useState(0);

  // 필터 어드민 옵션
  const [adminName, setAdminName] = useState("");
  const [grade, setgrade] = useState("");
  const [adminState, setAdminState] = useState("");

  // 어드민 목록 api 요청
  const getAdminList = async (take, page, userName, grade, adminState) => {
    try {
      const { data } = await axios.get(
        "api/user/admin/list?take=" +
          take +
          "&page=" +
          page +
          "&userName=" +
          userName +
          "&grade=" +
          grade +
          "&adminState=" +
          adminState
      );
      setAdminList(data.data);
      setPaginationCount(Math.ceil(data.data.total / searchParams.get("take")));
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 옵션 목록 호출
  const getOptionList = async () => {
    try {
      const { data } = await axios.get("/api/user/admin/list/grade");
      if (data.statusCode === 200) {
        setgradeList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/api/user/admin/list/state");
      if (data.statusCode === 200) {
        setAdminStateList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지네이션 함수
  const setPagination = (pageNumber) => {
    searchParams.set("page", pageNumber);
    setSearchParams(searchParams);
  };

  // 페이지 첫 로딩시 take, page 확인해서 강제 리다이랙팅
  useEffect(() => {
    if (
      searchParams.get("take") === null ||
      searchParams.get("page") === null
    ) {
      navigate("?take=" + 10 + "&page=" + 1 + "&grade=&adminState=&userName=");
    } else {
      getAdminList(
        searchParams.get("take"),
        searchParams.get("page"),
        searchParams.get("userName") || "",
        searchParams.get("grade") || "",
        searchParams.get("adminState") || ""
      );
    }
    getOptionList();
  }, [location.search]);

  // 뷰
  return (
    <>
      <Header />
      <Navbar menu={route} />
      <main className={"container"}>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2 fw-bold">관리자 목록</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            {/*<div className="btn-group me-2">*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    className="btn btn-sm btn-outline-secondary"*/}
            {/*  >*/}
            {/*    엑셀 내보내기*/}
            {/*  </button>*/}
            {/*</div>*/}
            <select
              className="form-select form-select-sm"
              value={searchParams.get("take")}
              onChange={(event) => {
                searchParams.set("take", event.target.value);
                searchParams.set("page", 1);
                setSearchParams(searchParams);
              }}
            >
              <option key={0} value={10}>
                10개씩 보기
              </option>
              <option key={1} value={30}>
                30개씩 보기
              </option>
              <option key={3} value={50}>
                50개씩 보기
              </option>
              <option key={4} value={100}>
                100개씩 보기
              </option>
            </select>
          </div>
        </div>
        <div className={"bg-light border my-3 py-3 "}>
          <form
            className={"align-items-center d-flex flex-column"}
            onSubmit={(event) => {
              event.preventDefault();
              searchParams.set("userName", adminName);
              searchParams.set("grade", grade);
              searchParams.set("adminState", adminState);
              searchParams.set("page", 1);
              setSearchParams(searchParams);
            }}
            onReset={async (event) => {
              setAdminName("");
              setAdminState("");
              setgrade("");
              searchParams.set("userName", "");
              searchParams.set("grade", "");
              searchParams.set("adminState", "");
              setSearchParams(searchParams);
            }}
          >
            <div className={" d-flex flex-md-row flex-column mb-3 w-100"}>
              <div className={"col mx-2 mb-3 mb-md-0"}>
                <label htmlFor="adminNameInput" className="form-label">
                  이름
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="adminNameInput"
                  placeholder="관리자 이름 입력해 주세요"
                  onChange={(event) => setAdminName(event.target.value)}
                />
              </div>
              <div className={"col mx-2 mb-3 mb-md-0"}>
                <label htmlFor="gradeInput" className="form-label">
                  관리자 레벨
                </label>
                <select
                  className="form-select"
                  onChange={(event) => setgrade(event.target.value)}
                >
                  <option key={0} value={""}>
                    전체
                  </option>
                  {gradeList.map((i, index) => (
                    <option key={index} value={i.key}>
                      {i.key}
                    </option>
                  ))}
                </select>
              </div>
              <div className={"col mx-2 mb-3 mb-md-0"}>
                <label htmlFor="adminStateInput" className="form-label">
                  관리자 상태
                </label>
                <select
                  className="form-select"
                  onChange={(event) => setAdminState(event.target.value)}
                >
                  <option key={0} value={""}>
                    전체
                  </option>
                  {adminStateList.map((i, index) => (
                    <option key={index} value={i.key}>
                      {i.key}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <button className="btn btn-outline-dark mx-2 px-3" type="reset">
                초기화
              </button>
              <button className="btn btn-primary mx-2 px-4" type={"submit"}>
                검색
              </button>
            </div>
          </form>
        </div>
        {/* 태이블 시작 */}
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered caption-top text-nowrap m-0">
            <caption className={"text-dark"}>
              검색결과 :{" "}
              <span className={"text-danger fw-bold"}>{adminList.total}</span>개
            </caption>
            <thead>
              <tr>
                <th>No</th>
                <th>이름</th>
                <th>아이디</th>
                <th>전화번호</th>
                <th>관리자레벨</th>
                <th>관리자상태</th>
                <th>생성일</th>
              </tr>
            </thead>
            <tbody>
              {adminList.results?.length === 0 ? (
                <tr>
                  <td className={"text-center"} colSpan={100}>
                    검색결과가 없습니다.
                  </td>
                </tr>
              ) : (
                adminList.results?.map((i, index) => (
                  <tr key={index}>
                    <td>
                      {(searchParams.get("page") - 1) *
                        searchParams.get("take") +
                        index +
                        1}
                    </td>
                    <td>{i.userName}</td>
                    <td>
                      <Link to={"/account/adminDetail/" + i.userId}>
                        {i.userId}
                      </Link>
                    </td>
                    <td>{i.phone}</td>
                    <td>{i.admin.grade}</td>
                    <td>{i.admin.adminState}</td>
                    <td>{moment(i.admin.createdAt).format("YYYY-MM-DD")}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {/* 태이블 끝 */}
        <nav aria-label="Page navigation example" className={"mt-4"}>
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <button
                className="page-link text-dark"
                onClick={() =>
                  setPagination(Number(searchParams.get("page")) - 1)
                }
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {[...Array(paginationCount)].map((i, index) => (
              <li className="page-item" key={index + 1}>
                <button
                  className={
                    Number(searchParams.get("page")) === index + 1
                      ? "page-link fw-bold"
                      : "page-link text-muted"
                  }
                  onClick={() => setPagination(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className="page-link text-dark"
                onClick={() =>
                  setPagination(Number(searchParams.get("page")) + 1)
                }
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </main>
    </>
  );
};

export default AdminList;
