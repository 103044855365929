import * as React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Dashboard,
  Home,
  Go,
  Ro,
  Zone,
  SignUp,
  MyPage,
  NotFound,
  UserList,
  AdminList,
  AdminDetail,
  DeviceList,
  DeviceDetail,
  DeviceNew,
  BrandList,
  BrandDetail,
  BrandNew,
  SeriesList,
  SeriesDetail,
  SeriesNew,
  ProductList,
  ProductDetail,
  ProductNew,
  Info,
  Stats,
  QnaList,
  QnaDetail,
  QnaNew,
  FormList,
  FormDetail,
  FormNew,
  SheetList,
  SheetNew,
  Suggest,
  VersionList,
  VersionNew,
  VersionDetail,
  MappingList,
  PickUpInfoList,
  PickUpInfoNew,
  PickUpInfoDetail,
  PolicyList,
  PolicyNew,
  PolicyDetail,
  NoticeDetail,
  NoticeList,
  NoticeNew,
} from "./pages";
import Login from "./pages/Login";

function App() {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/dashboard"} element={<Dashboard />} />
      <Route path={"/sellOrder/go"} element={<Go />} />
      <Route path={"/sellOrder/ro"} element={<Ro />} />
      <Route path={"/sellOrder/zone"} element={<Zone />} />
      <Route path={"/signup"} element={<SignUp />} />
      <Route path={"/login"} element={<Login />} />
      <Route path={"/MyPage"} element={<MyPage />} />
      <Route path={"/account/userList"} element={<UserList />} />
      <Route path={"/account/adminList"} element={<AdminList />} />
      <Route path={"/account/adminDetail/:id"} element={<AdminDetail />} />
      <Route path={"/device/deviceList"} element={<DeviceList />} />
      <Route path={"/device/deviceDetail/:id"} element={<DeviceDetail />} />
      <Route path={"/device/deviceNew"} element={<DeviceNew />} />
      <Route path={"/device/brandList"} element={<BrandList />} />
      <Route path={"/device/brandDetail/:id"} element={<BrandDetail />} />
      <Route path={"/device/brandNew"} element={<BrandNew />} />
      <Route path={"/device/seriesList"} element={<SeriesList />} />
      <Route path={"/device/seriesDetail/:id"} element={<SeriesDetail />} />
      <Route path={"/device/seriesNew"} element={<SeriesNew />} />
      <Route path={"/device/productList"} element={<ProductList />} />
      <Route path={"/device/productDetail/:id"} element={<ProductDetail />} />
      <Route path={"/device/productNew"} element={<ProductNew />} />
      <Route path={"/management/info"} element={<Info />} />
      <Route path={"/management/stats"} element={<Stats />} />
      <Route path={"/management/suggest"} element={<Suggest />} />
      <Route path={"/management/versionList"} element={<VersionList />} />
      <Route path={"/management/versionNew"} element={<VersionNew />} />
      <Route
        path={"/management/versionDetail/:id"}
        element={<VersionDetail />}
      />
      <Route path={"/management/pickUpInfoList"} element={<PickUpInfoList />} />
      <Route path={"/management/pickUpInfoNew"} element={<PickUpInfoNew />} />
      <Route
        path={"/management/pickUpInfoDetail/:id"}
        element={<PickUpInfoDetail />}
      />

      <Route path={"/management/policyList"} element={<PolicyList />} />
      <Route path={"/management/policyNew"} element={<PolicyNew />} />
      <Route path={"/management/policyDetail/:id"} element={<PolicyDetail />} />
      <Route path={"/notice/noticeList"} element={<NoticeList />} />
      <Route path={"/notice/noticeNew"} element={<NoticeNew />} />
      <Route path={"/notice/noticeDetail/:id"} element={<NoticeDetail />} />
      <Route path={"/query/qnaList"} element={<QnaList />} />
      <Route path={"/query/qnaDetail/:id"} element={<QnaDetail />} />
      <Route path={"/query/qnaNew"} element={<QnaNew />} />
      <Route path={"/query/formList"} element={<FormList />} />
      <Route path={"/query/formDetail/:id"} element={<FormDetail />} />
      <Route path={"/query/formNew"} element={<FormNew />} />
      <Route path={"/query/sheetList"} element={<SheetList />} />
      <Route path={"/query/sheetNew"} element={<SheetNew />} />
      <Route path={"/query/mappingList"} element={<MappingList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
