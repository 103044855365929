import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const BrandDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  // 편집 불가능한 요소
  const [brandId, setBrandId] = useState("");
  const [brandCreatedDate, setBrandCreatedDate] = useState("");
  const [brandUpdatedDate, setBrandUpdatedDate] = useState("");

  // 편집 가능한 요소
  const [brandTitle, setBrandTitle] = useState("");
  const [brandVorder, setBrandVorder] = useState(0);
  const [brandImage, setBrandImage] = useState("");
  const [brandImageData, setBrandImageData] = useState(null);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState([]);

  // 어드민 정보 호출
  const getBrandInfo = async () => {
    try {
      const { data } = await axios.get("/api/brand/admin/info/" + id);
      setBrandId(data.data.id);
      setBrandCreatedDate(moment(data.data.createdAt).format("YYYY-MM-DD"));
      setBrandUpdatedDate(
        moment(data.data.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      );
      setBrandTitle(data.data.title);
      setBrandVorder(data.data.vorder);
      setBrandImage(data.data.image.url);
      var result = [];

      for (let i = 0; i < data.data.devices.length; i++) {
        result.push(data.data.devices[i].id);
      }
      setSelectedDeviceList(result);
    } catch (e) {
      console.log(e);
    }
  };

  const modifyBrandInfo = async () => {
    const formData = new FormData();
    formData.append("title", brandTitle.trim());
    formData.append("vorder", brandVorder);
    formData.append("image", brandImageData);
    formData.append("deviceIdArr", selectedDeviceList);
    try {
      const { data } = await axios.put(
        "/api/brand/admin/info/" + id,
        formData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  const getDeviceList = async () => {
    try {
      const { data } = await axios.get("/api/brand/admin/list/filter/device");
      setDeviceList(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getDeviceList();
    getBrandInfo();
  }, []);

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">브랜드 상세보기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (!brandTitle) {
              alert("첫글자에 공백이 포함되어 있습니다");
              return false;
            }
            modifyBrandInfo();
          }}
        >
          <div className="mb-3">
            <label htmlFor="brandIdInput" className="form-label">
              id
            </label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              id="brandIdInput"
              value={brandId}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="brandCreatedDateInput" className="form-label">
              생성일
            </label>
            <input
              value={brandCreatedDate}
              disabled={true}
              type="text"
              className="form-control"
              id="brandCreatedDateInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="brandUpdatedDateInput" className="form-label">
              최근 업데이트
            </label>
            <input
              value={brandUpdatedDate}
              disabled={true}
              type="text"
              className="form-control"
              id="brandUpdatedDateInput"
            />
          </div>

          <hr />
          <div className="mb-3">
            <label className="form-label">디바이스 선택</label>
            {deviceList.map((i, index) => (
              <div className="form-check">
                <input
                  onChange={(event) => {
                    var updatedList = [...selectedDeviceList];
                    if (event.target.checked) {
                      updatedList = [
                        ...selectedDeviceList,
                        Number(event.target.value),
                      ];
                    } else {
                      updatedList.splice(
                        selectedDeviceList.indexOf(Number(event.target.value)),
                        1
                      );
                    }
                    setSelectedDeviceList(updatedList);
                  }}
                  value={i.id}
                  className="form-check-input"
                  type="checkbox"
                  id={"deviceListCheckbox" + index}
                  checked={!!selectedDeviceList?.includes(Number(i.id))}
                />
                <label
                  className="form-check-label"
                  htmlFor={"deviceListCheckbox" + index}
                >
                  {i.title}
                </label>
              </div>
            ))}
          </div>
          <div className="mb-3">
            <label htmlFor="brandNameInput" className="form-label">
              브랜드 이름
            </label>
            <input
              defaultValue={brandTitle}
              type="text"
              className="form-control"
              id="brandNameInput"
              required={true}
              onChange={(event) => setBrandTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="brandVorderInput" className="form-label">
              노출 순서
            </label>
            <input
              min={0}
              onChange={(event) => setBrandVorder(event.target.value)}
              value={brandVorder}
              required={true}
              type="number"
              className="form-control"
              id="brandVorderInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              노출 이미지
            </label>
            <img
              className={"d-block img-thumbnail mb-3"}
              style={{ height: "200px" }}
              src={brandImage}
            />
            <input
              onChange={(event) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = function (e) {
                  setBrandImage(e.target.result.toString());
                };
                setBrandImageData(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              id="formFile"
              accept="image/png, image/jpeg"
            />
          </div>
          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default BrandDetail;
