import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-3 bg-light position-fixed bottom-0 w-100">
      <div className="container ">
        <span className="text-muted">
          ⓒ 2021. 21market Inc. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
