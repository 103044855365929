import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const QnaNew = () => {
  let navigate = useNavigate();

  // 편집 가능한 요소
  const [qnaTitle, setQnaTitle] = useState("");
  const [qnaType, setQnaType] = useState("single");
  const [qnaShowYn, setQnaShowYn] = useState(true);
  const [qnaImg, setQnaImg] = useState(null);

  // 어드민 정보 호출

  useEffect(() => {}, []);

  const createQna = async () => {
    const formData = new FormData();
    formData.append("title", qnaTitle);
    formData.append("type", qnaType);
    formData.append("image", qnaImg);
    formData.append("showYn", qnaShowYn);
    try {
      const { data } = await axios.post("/api/question/admin/info", formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      if (data.statusCode === 201) {
        alert(
          "질문이 등록되었습니다.\n상세페이지에서 답변을 등록해주시면 해당 Q&A를설문지에 등록할 수 있습니다."
        );
        navigate("/query/qnaDetail/" + data.data.id);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">Q&A 생성</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            createQna();
          }}
        >
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <h5>Q&A 등록방법(필독)</h5>
            1. 질문을 먼저 등록하고 아래 질문등록완료 버튼을 눌러주세요.
            <br />
            2. 이어지는 상세페이지에서 답변들을 추가하거나 삭제하여 답변 구성을
            완성해주세요.
            <br />
            3.답변 구성이 완성되면 아래 답변등록완료 버튼을 눌러주세요.
            <hr />
            답변등록 완료를 누르지 않으면 설문지를 구성할때 해당 질문이 보이지
            않습니다.
            <br />
            답변등록을 완료하면 첨부사진 이외에 Q&A 수정이 불가능합니다.
            신중하게 만들어주세요.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">질문내용</label>
            <input
              placeholder={"액정파손이 있나요?"}
              defaultValue={qnaTitle}
              type="text"
              className="form-control"
              required={true}
              onChange={(event) => setQnaTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">질문타입</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={qnaType}
              onChange={(event) => setQnaType(event.target.value)}
            >
              <option value={"single"}>단일선택</option>
              <option value={"multiple"}>복수선택</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">이미지</label>
            <br />
            <input
              onChange={(event) => setQnaImg(event.target.files[0])}
              id={"qnaImgInput"}
              className="form-control"
              type="file"
              accept="image/png, image/jpeg"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">공개여부</label>
            <select
              className="form-select"
              value={qnaShowYn}
              onChange={(event) => setQnaShowYn(event.target.value)}
            >
              <option value={true}>공개</option>
              <option value={false}>비공개</option>
            </select>
          </div>
          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장 후 질문 등록페이지로 이동
          </button>
        </form>
      </main>
    </>
  );
};

export default QnaNew;
