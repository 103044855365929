import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import alert from "bootstrap/js/src/alert";

const PickUpInfoDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [pickUpId, setPickUpId] = useState(0);
  const [pickUpCreateDate, setPickUpCreateDate] = useState("");
  const [pickUpUpdateDate, setPickUpUpdateDate] = useState("");

  // 편집 가능한 요소
  const [pickUpTitle, setPickUpTitle] = useState("");
  const [pickUpStartDate, setPickUpStartDate] = useState(null);
  const [pickUpEndDate, setPickUpEndDate] = useState(null);

  const [pickUpOffStartDate, setPickUpOffStartDate] = useState(null);
  const [pickUpOffEndDate, setPickUpOffEndDate] = useState(null);

  const [pickUpTimes, setPickUpTimes] = useState([]);
  const [pickUpRegions, setPickUpRegions] = useState([]);

  const [timeWeekType, setTimeWeekType] = useState("weekday");
  const [timeStartTime, setTimeStartTime] = useState(null);
  const [timeEndTime, setTimeEndTime] = useState(null);
  const [timeCapacity, setTimeCapacity] = useState(1);

  const [regionList, setRegionList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchRegionList, setSearchRegionList] = useState([]);
  const [searchCheckedList, setSearchCheckedList] = useState([]);
  const [mappingRegionList, setMappingRegionList] = useState([]);
  const [mappingCheckedList, setMappingCheckedList] = useState([]);

  const modifyPickUpInfo = async () => {
    try {
      const { data } = await axios.patch(
        "/api/pickup/admin/info/" + id,
        JSON.stringify({
          startDate: pickUpStartDate.toString(),
          endDate: pickUpEndDate.toString(),
          title: pickUpTitle.toString(),
          offStartDate: pickUpOffStartDate?.toString(),
          offEndDate: pickUpOffEndDate?.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        window.alert("정상적으로 수정되었습니다");
        navigate(0);
      } else {
        window.alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPickUpInfo = async () => {
    try {
      const { data } = await axios.get("/api/pickup/admin/info/" + id, {
        headers: { "content-type": "application/json" },
      });
      setPickUpTitle(data.data.title);
      setPickUpId(data.data.id);
      setPickUpCreateDate(moment(data.data.createdAt).format("YYYY-MM-DD"));
      setPickUpUpdateDate(moment(data.data.updatedAt).format("YYYY-MM-DD"));
      setPickUpStartDate(data.data.startDate);
      setPickUpEndDate(data.data.endDate);
      setPickUpOffStartDate(data.data.offStartDate);
      setPickUpOffEndDate(data.data.offEndDate);
      setPickUpTimes(data.data.pickupTimes);
      setPickUpRegions(data.data.pickupRegions);
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/api/pickup/admin/list/region/sido");
      setRegionList(data.data);
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get(
        "/api/pickup/admin/list/region/belong/" + id
      );
      setMappingRegionList(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  const searchRegion = async () => {
    try {
      const { data } = await axios.get(
        "/api/pickup/admin/list/region/na?sidoName=" +
          selectedRegion +
          "&searchKeyword=" +
          searchKeyword,
        {
          headers: { "content-type": "application/json" },
        }
      );
      setSearchRegionList(data.data);
    } catch (e) {
      console.log(e);
    }
    try {
      const { data } = await axios.get("/api/pickup/admin/list/region/sido");
      setRegionList(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getPickUpInfo();
  }, []);

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                운영시간 추가하기
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">주중/주말 여부</label>
                <select
                  className="form-select"
                  required={true}
                  defaultValue={timeWeekType}
                  onChange={(event) => setTimeWeekType(event.target.value)}
                >
                  <option value={"weekday"}>주중</option>
                  <option value={"weekend"}>주말</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">시작시간</label>
                <input
                  className="form-control"
                  required={true}
                  type={"time"}
                  value={timeStartTime}
                  onChange={(event) => setTimeStartTime(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">종료시간</label>
                <input
                  className="form-control"
                  required={true}
                  type={"time"}
                  value={timeEndTime}
                  onChange={(event) => setTimeEndTime(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">수용인원</label>
                <input
                  className="form-control"
                  required={true}
                  type={"number"}
                  value={timeCapacity}
                  onChange={(event) => setTimeCapacity(event.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={async () => {
                  if (timeStartTime === null || timeEndTime === null) {
                    alert("시간을 입력해주세요");
                    return false;
                  }
                  try {
                    const { data } = await axios.post(
                      "/api/pickup/admin/info/time",
                      JSON.stringify({
                        pickupGroupId: id.toString(),
                        startTime: timeStartTime.toString(),
                        endTime: timeEndTime.toString(),
                        weekType: timeWeekType.toString(),
                        capacity: timeCapacity.toString(),
                      }),
                      {
                        headers: { "content-type": "application/json" },
                      }
                    );
                    if (data.statusCode === 201) {
                      navigate(0);
                    } else {
                      alert(
                        "정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요"
                      );
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                추가
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addRegions"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                소속지역 추가하기
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body">
              <div className="mb-3 ">
                <label className="form-label">검색 필터</label>
                <div className={"input-group"}>
                  <select
                    className="form-select"
                    required={true}
                    onChange={(event) => setSelectedRegion(event.target.value)}
                  >
                    <option value={"none"} hidden={true}>
                      시도를 선택해주세요
                    </option>
                    {regionList.map((i, index) => (
                      <option value={i.sidoName}>{i.sidoName}</option>
                    ))}
                  </select>
                  <input
                    className={"form-control"}
                    placeholder={"00동"}
                    value={searchKeyword}
                    onChange={(event) => setSearchKeyword(event.target.value)}
                  />
                  <button
                    type={"button"}
                    className={"btn btn-dark"}
                    onClick={() => {
                      if (selectedRegion === "") {
                        alert("시도를 선택해주세요");
                      } else {
                        searchRegion();
                      }
                    }}
                  >
                    검색하기
                  </button>
                </div>
              </div>
              <div className={"mb-3 "}>
                <label className="form-label">검색 결과</label>
                <div
                  className={"overflow-auto bg-light"}
                  style={{ height: "230px" }}
                >
                  <table className="table table-bordered m-0 border bg-light">
                    <tbody>
                      <tr className={"bg-dark sticky-top"}>
                        <td
                          className={
                            "d-flex justify-content-between align-items-center"
                          }
                        >
                          <div className="form-check m-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkAll"
                              onChange={(event) => {
                                if (event.target.checked) {
                                  let result = searchRegionList.map(
                                    ({ id }) => id
                                  );
                                  setSearchCheckedList(result);
                                } else {
                                  setSearchCheckedList([]);
                                }
                              }}
                            />
                            <label
                              className="form-check-label text-white"
                              htmlFor="checkAll"
                            >
                              전체 선택
                            </label>
                          </div>
                          <button
                            type={"button"}
                            className={"btn btn-sm btn-secondary"}
                            onClick={async () => {
                              try {
                                const { data } = await axios.patch(
                                  "/api/pickup/admin/info/region/map/" + id,
                                  JSON.stringify({
                                    regionIds: searchCheckedList.toString(),
                                  }),
                                  {
                                    headers: {
                                      "content-type": "application/json",
                                    },
                                  }
                                );
                                if (data.statusCode === 200) {
                                  window.alert("완료");
                                  setSearchCheckedList([]);
                                  searchRegion();
                                  getPickUpInfo();
                                } else {
                                  window.alert(
                                    "정상적으로 추가되지 않음 관리자 문의"
                                  );
                                }
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          >
                            선택항목 추가
                          </button>
                        </td>
                      </tr>
                      {searchRegionList?.map((i, index) => (
                        <tr key={index}>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={i.id}
                                value={i.id}
                                onChange={(event) => {
                                  var updatedList = [...searchCheckedList];
                                  if (event.target.checked) {
                                    updatedList = [
                                      ...searchCheckedList,
                                      Number(event.target.value),
                                    ];
                                  } else {
                                    updatedList.splice(
                                      searchCheckedList.indexOf(
                                        Number(event.target.value)
                                      ),
                                      1
                                    );
                                  }
                                  setSearchCheckedList(updatedList);
                                }}
                                checked={
                                  !!searchCheckedList?.includes(Number(i.id))
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={i.id}
                              >
                                {i.fullName}
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={"mb-3 "}>
                <label className="form-label">맵핑 현황</label>
                <div
                  className={"overflow-auto bg-light"}
                  style={{ height: "230px" }}
                >
                  <table className="table table-bordered m-0 border bg-light">
                    <tbody>
                      <tr className={"bg-dark sticky-top"}>
                        <td
                          className={
                            "d-flex justify-content-between align-items-center"
                          }
                        >
                          <div className="form-check m-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="mappingCheckAll"
                              onChange={(event) => {
                                if (event.target.checked) {
                                  let result = mappingRegionList.map(
                                    ({ id }) => id
                                  );
                                  setMappingCheckedList(result);
                                } else {
                                  setMappingCheckedList([]);
                                }
                              }}
                            />
                            <label
                              className="form-check-label text-white"
                              htmlFor="mappingCheckAll"
                            >
                              전체 선택
                            </label>
                          </div>
                          <button
                            type={"button"}
                            className={"btn btn-sm btn-secondary"}
                            onClick={async () => {
                              try {
                                const { data } = await axios.patch(
                                  "/api/pickup/admin/info/region/unmap",
                                  JSON.stringify({
                                    regionIds: mappingCheckedList.toString(),
                                  }),
                                  {
                                    headers: {
                                      "content-type": "application/json",
                                    },
                                  }
                                );
                                if (data.statusCode === 200) {
                                  window.alert("완료");
                                  setMappingCheckedList([]);
                                  getPickUpInfo();
                                } else {
                                  window.alert(
                                    "정상적으로 추가되지 않음 관리자 문의"
                                  );
                                }
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          >
                            선택항목 삭제
                          </button>
                        </td>
                      </tr>
                      {mappingRegionList?.map((i, index) => (
                        <tr key={index}>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={i.id}
                                value={i.id}
                                onChange={(event) => {
                                  var updatedList = [...mappingCheckedList];
                                  if (event.target.checked) {
                                    updatedList = [
                                      ...mappingCheckedList,
                                      Number(event.target.value),
                                    ];
                                  } else {
                                    updatedList.splice(
                                      mappingCheckedList.indexOf(
                                        Number(event.target.value)
                                      ),
                                      1
                                    );
                                  }
                                  setMappingCheckedList(updatedList);
                                }}
                                checked={
                                  !!mappingCheckedList?.includes(Number(i.id))
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={i.id}
                              >
                                {i.fullName}
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">픽업구역 상세보기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            modifyPickUpInfo();
          }}
        >
          <div className="mb-3">
            <label className="form-label">픽업구역 id</label>
            <input
              placeholder={"송파구역"}
              value={pickUpId}
              type="text"
              disabled={true}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">생성일</label>
            <input
              defaultValue={pickUpCreateDate}
              type="text"
              disabled={true}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">업데이트일</label>
            <input
              defaultValue={pickUpUpdateDate}
              type="text"
              disabled={true}
              className="form-control"
            />
          </div>
          <hr />
          <div className="mb-3">
            <label className="form-label">픽업 구역명</label>
            <input
              placeholder={"송파구역"}
              defaultValue={pickUpTitle}
              type="text"
              className="form-control"
              required={true}
              onChange={(event) => setPickUpTitle(event.target.value)}
            />
          </div>
          <div className={"row"}>
            <div className="mb-3 col">
              <label className="form-label">운영시작일</label>
              <input
                onChange={(event) => setPickUpStartDate(event.target.value)}
                required={true}
                className="form-control"
                value={pickUpStartDate}
                type={"date"}
                max={"9999-12-31"}
              />
            </div>
            <div className={"col-auto my-auto"}>~</div>
            <div className="mb-3 col">
              <label className="form-label">운영종료일</label>
              <input
                onChange={(event) => setPickUpEndDate(event.target.value)}
                required={true}
                className="form-control"
                defaultValue={pickUpEndDate}
                type={"date"}
                max={"9999-12-31"}
              />
            </div>
            <div className={"col-auto mb-3 mt-auto"}>
              <button
                className={"btn btn-primary"}
                type={"button"}
                onClick={() => {
                  setPickUpStartDate("");
                  setPickUpEndDate("");
                }}
              >
                초기화
              </button>
            </div>
          </div>
          <div className={"row"}>
            <div className="mb-3 col">
              <label className="form-label">휴무시작일</label>
              <input
                onChange={(event) => setPickUpOffStartDate(event.target.value)}
                className="form-control"
                value={pickUpOffStartDate}
                type={"date"}
                max={"9999-12-31"}
              />
            </div>
            <div className={"col-auto my-auto"}>~</div>
            <div className="mb-3 col">
              <label className="form-label">휴무종료일</label>
              <input
                onChange={(event) => setPickUpOffEndDate(event.target.value)}
                className="form-control"
                value={pickUpOffEndDate}
                type={"date"}
                max={"9999-12-31"}
              />
            </div>
            <div className={"col-auto mb-3 mt-auto"}>
              <button
                className={"btn btn-primary"}
                type={"button"}
                onClick={() => {
                  setPickUpOffStartDate("");
                  setPickUpOffEndDate("");
                }}
              >
                초기화
              </button>
            </div>
          </div>
          <hr />
          <div className="mb-3 d-flex justify-content-between">
            <h5>운영시간</h5>
            <div className="">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                + 운영시간 추가
              </button>
            </div>
          </div>
          {pickUpTimes.length !== 0 ? (
            <table className="table mb-3 table-sm table-striped m-0 align-middle border">
              <tbody>
                {pickUpTimes?.map((i, index) => (
                  <tr key={index} className={"text-center"}>
                    <td>{i.weekType === "weekday" ? "주중" : "주말"}</td>
                    <td>
                      {i.startTime} ~ {i.endTime}
                    </td>
                    <td>{i.capacity}명</td>
                    <td>
                      <button
                        type={"button"}
                        className={"btn btn-outline-dark"}
                        onClick={async () => {
                          try {
                            const { data } = await axios.delete(
                              "/api/pickup/admin/info/time/" + i.id,
                              {
                                headers: { "content-type": "application/json" },
                              }
                            );
                            if (data.statusCode === 200) {
                              alert("정상적으로 수정되었습니다");
                              navigate(0);
                            }
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                      >
                        삭제하기
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className={"border py-4 mb-3 bg-light text-center text-muted"}>
              등록된 운영시간이 없습니다
            </div>
          )}
          <div className="mb-3 justify-content-between">
            <h5>소속지역</h5>
          </div>
          <div className={"border py-4 mb-3 bg-light text-center fw-bold"}>
            <span className={"text-danger"}>{pickUpRegions.length}</span>개의
            지역
            <button
              type={"button"}
              className={"btn btn-dark ms-4"}
              data-bs-toggle="modal"
              data-bs-target="#addRegions"
            >
              편집하기
            </button>
          </div>
          <hr />
          <Link className="btn btn-outline-secondary me-3 px-4" to={-1}>
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default PickUpInfoDetail;
