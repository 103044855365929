import React from "react";

const Modal = (props) => {
  return (
    <div className={"modal fade"} id={props.id}>
      <div
        className={"modal-dialog-centered modal-dialog " + props.size}
        role="document"
      >
        <div className="modal-content rounded-6 shadow">
          <div className="modal-header border-bottom-0">
            <h5 className="modal-title">{props.title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body py-0">
            <p>{props.subTitle}</p>
          </div>
          <div className="modal-footer flex-column border-top-0">
            <button
              type="button"
              className="btn btn-lg btn-primary w-100 mx-0 mb-2"
              data-bs-dismiss="modal"
              onClick={props.confirm}
            >
              {props.confirmText}
            </button>
            {props.closeText ? (
              <button
                type="button"
                className="btn btn-lg btn-light w-100 mx-0"
                data-bs-dismiss="modal"
              >
                {props.closeText}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
