import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const BrandNew = () => {
  let navigate = useNavigate();

  // 편집 가능한 요소
  const [brandTitle, setBrandTitle] = useState("");
  const [brandVorder, setBrandVorder] = useState(0);
  const [brandImage, setBrandImage] = useState("");
  const [brandImageData, setBrandImageData] = useState(null);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState([]);

  const modifybrandInfo = async () => {
    const formData = new FormData();
    formData.append("title", brandTitle);
    formData.append("vorder", brandVorder);
    formData.append("image", brandImageData);
    formData.append("deviceIdArr", selectedDeviceList);
    try {
      const { data } = await axios.post("/api/brand/admin/info/", formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      if (data.statusCode === 201) {
        alert("정보수정이 완료되었습니다");
        navigate(-1);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  const getDeviceList = async () => {
    try {
      const { data } = await axios.get("/api/brand/admin/list/filter/device");
      setDeviceList(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getDeviceList();
  }, []);

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">브랜드 생성하기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            modifybrandInfo();
          }}
        >
          <div className="mb-3">
            <label htmlFor="brandNameInput" className="form-label">
              브랜드 이름
            </label>
            <input
              placeholder={"삼성"}
              defaultValue={brandTitle}
              type="text"
              className="form-control"
              id="brandNameInput"
              required={true}
              onChange={(event) => setBrandTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="brandVorderInput" className="form-label">
              노출 순서
            </label>
            <input
              min={0}
              onChange={(event) => setBrandVorder(event.target.value)}
              value={brandVorder}
              required={true}
              type="number"
              className="form-control"
              id="brandVorderInput"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              노출 이미지
            </label>
            <img
              className={"d-block img-thumbnail mb-3"}
              style={{ height: "200px" }}
              src={brandImage}
            />
            <input
              required={true}
              onChange={(event) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = function (e) {
                  setBrandImage(e.target.result.toString());
                };
                setBrandImageData(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              id="formFile"
              accept="image/png, image/jpeg"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">디바이스 선택</label>
            {deviceList.map((i, index) => (
              <div className="form-check">
                <input
                  onChange={(event) => {
                    var updatedList = [...selectedDeviceList];
                    if (event.target.checked) {
                      updatedList = [
                        ...selectedDeviceList,
                        Number(event.target.value),
                      ];
                    } else {
                      updatedList.splice(
                        selectedDeviceList.indexOf(Number(event.target.value)),
                        1
                      );
                    }
                    setSelectedDeviceList(updatedList);
                  }}
                  value={i.id}
                  className="form-check-input"
                  type="checkbox"
                  id={"deviceListCheckbox" + index}
                />
                <label
                  className="form-check-label"
                  htmlFor={"deviceListCheckbox" + index}
                >
                  {i.title}
                </label>
              </div>
            ))}
          </div>
          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"submit"}
            to={-1}
          >
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default BrandNew;
