import React, { useEffect, useState } from "react";
import { Header, Navbar } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Stats = () => {
  let navigate = useNavigate();
  const route = [
    { name: "기본정보", link: "/management/info" },
    { name: "통계", link: "/management/stats" },
    { name: "약관", link: "/management/policyList" },
    { name: "정책", link: "tou" },
    { name: "버전", link: "/management/versionList" },
    { name: "제안사항", link: "/management/suggest" },
  ];

  const nameList = {
    checkPriceUserCnt: "누적시세조회",
    checkPriceUserCntUnit: "누적시세조회 단위",
    buyProductCnt: "누적판매대수",
    buyProductCntUnit: "누적판매대수 단위",
    buyProductSum: "총 판매금액",
    buyProductSumUnit: "총 판매금액 단위",
    checkProductTime: "검수 소요시간",
    checkProductTimeUnit: "검수 소요시간 단위",
    satisfactionRate: "소비자 만족도",
    satisfactionRateUnit: "소비자 만족도 단위",
  };

  const [companyStats, setCompanyStats] = useState({});

  const getCompanyStats = async () => {
    try {
      const { data } = await axios.get("/api/company/stats");
      if (data.statusCode === 200) {
        setCompanyStats(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const modifyCompanyStats = async (value) => {
    try {
      const { data } = await axios.patch("/api/company/stats", value);
      if (data.statusCode === 200) {
        console.log("완료");
        alert("정상적으로 수정되었습니다");
        navigate(0);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCompanyStats();
  }, []);
  return (
    <>
      <Header />
      <Navbar menu={route} />

      <main className="container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2 fw-bold">통계</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const data = new FormData(event.target);

            const value = Object.fromEntries(data.entries());
            modifyCompanyStats(value);
          }}
        >
          <div className={"row"}>
            {Object.keys(companyStats).map((key, i) => (
              <div className="mb-3 col-6" key={i}>
                <label className="form-label">
                  {nameList[key] ? nameList[key] : key}
                </label>
                <input
                  disabled={
                    key === "id" ||
                    key === "title" ||
                    key === "createdAt" ||
                    key === "updatedAt"
                  }
                  name={key}
                  type="text"
                  className="form-control"
                  defaultValue={companyStats[key]}
                />
              </div>
            ))}
          </div>

          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default Stats;
