import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const FormDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  // 편집 불가능한 요소
  const [formId, setFormId] = useState("");

  // 편집 가능한 요소
  const [formTitle, setFormTitle] = useState("");
  const [formType, setFormType] = useState("");
  const [formShowYn, setFormShowYn] = useState(true);
  const [formQuestions, setFormQuestions] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [formDevice, setFormDevice] = useState(1);

  const [formIsConfirmed, setFormIsConfirmed] = useState(true);

  const getOption = async () => {
    try {
      const { data } = await axios.get(
        "/api/checklist/admin/list/filter/device",
        {
          headers: { "content-type": "application/json" },
        }
      );
      setDeviceList(data.data);
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };
  // 어드민 정보 호출
  const getFormInfo = async () => {
    try {
      const { data } = await axios.get("/api/checklist/admin/info/" + id);
      console.log(data);
      setFormId(data.data.id);
      setFormTitle(data.data.title);
      setFormShowYn(data.data.showYn);
      setFormQuestions(data.data.questions);
      setFormDevice(data.data.device.id);
    } catch (e) {
      console.log(e);
    }
  };

  const modifyFormInfo = async () => {
    try {
      const { data } = await axios.put(
        "/api/checklist/admin/info/" + id,
        JSON.stringify({
          showYn: formShowYn,
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getFormInfo();
    getOption();
  }, []);

  return (
    <>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">설문지 상세보기</h1>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            modifyFormInfo();
          }}
        >
          <div className="mb-3">
            <label htmlFor="formIdInput" className="form-label">
              id
            </label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              id="formIdInput"
              value={formId}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">디바이스</label>
            <select
              disabled={true}
              className="form-select"
              value={formDevice}
              onChange={(event) => setFormDevice(event.target.value)}
            >
              {deviceList.map((i, index) => (
                <option key={index} value={i.id}>
                  {i.title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="formNameInput" className="form-label">
              질문내용
            </label>
            <input
              disabled={true}
              defaultValue={formTitle}
              type="text"
              className="form-control"
              id="formNameInput"
              required={true}
              onChange={(event) => setFormTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formIsActiveInput" className="form-label">
              공개여부
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={formShowYn}
              onChange={(event) => setFormShowYn(event.target.value)}
            >
              <option value={true}>공개</option>
              <option value={false}>비공개</option>
            </select>
          </div>
          <hr />
          <div className="mb-3 d-flex justify-content-between">
            <h5>Q&A 목록조회</h5>
          </div>
          {formQuestions?.map((i, index) => (
            <div className={"row bg-light mb-4 border border-dark"} key={index}>
              <div className="modal-header rounded-0 border-bottom-0 bg-dark text-white">
                <div>
                  <h5 className="modal-title">
                    <span className="badge bg-secondary me-3">
                      Q{index + 1}
                    </span>
                    <br />
                    {i.title}
                  </h5>
                  <span className={"text-white-50"}>
                    ID : {i.id} |{" "}
                    {i.type === "single" ? "단일선택" : "복수선택"}
                  </span>
                </div>
                {i.image ? (
                  <img
                    className={" img-thumbnail"}
                    style={{ height: "80px" }}
                    src={i.image?.url}
                  />
                ) : (
                  <div
                    className={"d-flex img-thumbnail text-black-50 px-3"}
                    style={{ height: "80px" }}
                  >
                    <span>이미지가 없습니다</span>
                  </div>
                )}
              </div>

              <table className="table table-sm table-striped  table-bordered m-0 align-middle">
                <thead>
                  <tr>
                    <th className={"col-1"}>No</th>
                    <th className={"col-1"}>Id</th>
                    <th className={"col-4"}>답변내용</th>
                    <th className={"col-2"}>답변타입</th>
                    <th className={"col"}>이미지</th>
                  </tr>
                </thead>
                <tbody>
                  {i.answers?.map((i, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{i.id}</td>
                      <td>{i.title}</td>
                      <td>{i.type}</td>
                      <td>
                        {i.image ? (
                          <img style={{ height: "50px" }} src={i.image?.url} />
                        ) : (
                          <div style={{ height: "50px" }} />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}

          <Link className="btn btn-outline-secondary me-3 px-4" to={-1}>
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default FormDetail;
