import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components";

const NotFound = () => {
  return (
    <>
      <div className="d-flex container-fluid vh-100 justify-content-center align-items-center">
        <div className={"col-md-4 col"}>
          <h1 className="h3 mb-5 fw-bold text-center text-primary">
            404 페이지를 찾을 수 없습니다
          </h1>
          <div className={"d-flex justify-content-between"}>
            <Link className={"text-muted text-decoration-none"} to={-1}>
              뒤로 이동
            </Link>
            <Link className={"text-muted text-decoration-none"} to={"/"}>
              홈 으로 이동
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
