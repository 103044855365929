import React, { useEffect, useState } from "react";
import { Header, Modal } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const QnaDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  // 편집 불가능한 요소
  const [qnaId, setQnaId] = useState("");

  // 편집 가능한 요소
  const [qnaTitle, setQnaTitle] = useState("");
  const [qnaType, setQnaType] = useState("");
  const [qnaShowYn, setQnaShowYn] = useState(true);
  const [qnaAnswers, setQnaAnswers] = useState([]);
  const [qnaImg, setQnaImg] = useState(null);
  const [qnaIsConfirmed, setQnaIsConfirmed] = useState(true);

  const [answerTitle, setAnswerTitle] = useState("");
  const [answerType, setAnswerType] = useState("minus_finish");
  const [answerImg, setAnswerImg] = useState(null);

  const [answerTypeList, setAnswerTypeList] = useState([]);

  // 어드민 정보 호출
  const getQnaInfo = async () => {
    try {
      const { data } = await axios.get("/api/question/admin/info/" + id);

      setQnaId(data.data.id);
      setQnaTitle(data.data.title);
      setQnaType(data.data.type);
      setQnaAnswers(data.data.answers);
      setQnaImg(data.data.image);
      setQnaIsConfirmed(data.data.isConfirmed);
      setQnaShowYn(data.data.showYn);
    } catch (e) {
      console.log(e);
    }
  };
  const getAnswerList = async () => {
    try {
      const { data } = await axios.get(
        "/api/question/admin/list/filter/answer/type"
      );
      console.log(data);
      setAnswerTypeList(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const modifyQnaInfo = async () => {
    try {
      const { data } = await axios.put(
        "/api/question/admin/info/" + id,
        JSON.stringify({
          showYn: qnaShowYn,
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      if (e.request.status === 400) {
        const errorMsg = JSON.parse(e.request.response);
        alert(errorMsg.message);
      } else {
        console.log(e);
      }
    }
  };

  const changeAnswerImg = async (answerId, Imgfile) => {
    try {
      const formData = new FormData();
      formData.append("image", Imgfile);
      const { data } = await axios.put(
        "/api/question/admin/info/img/answer/" + answerId,
        formData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteAnswerImg = async (answerId) => {
    try {
      const { data } = await axios.delete(
        "/api/question/admin/info/answer/img/" + answerId,
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const changeQnaImg = async (Imgfile) => {
    console.log("하ㅣㄴ");
    try {
      const formData = new FormData();
      formData.append("image", Imgfile);
      const { data } = await axios.put(
        "/api/question/admin/info/img/" + id,
        formData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteQnaImg = async () => {
    try {
      const { data } = await axios.delete(
        "/api/question/admin/info/img/" + id,
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 200) {
        alert("정보수정이 완료되었습니다");
        navigate(0);
      } else {
        alert("정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요");
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 페이지 접근시 바로 어드민 정보, 드롭다운 메뉴 요소 호출
  useEffect(() => {
    getQnaInfo();
    getAnswerList();
  }, []);

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                답변 추가하기
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">답변내용</label>
                <input
                  placeholder={"액정파손"}
                  defaultValue={answerTitle}
                  type="text"
                  className="form-control"
                  required={true}
                  onChange={(event) => setAnswerTitle(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">답변타입</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={answerType}
                  onChange={(event) => setAnswerType(event.target.value)}
                >
                  {answerTypeList.map((i, index) => (
                    <option key={index} value={i.value}>
                      {i.key}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">이미지</label>
                <br />
                <input
                  onChange={(event) => setAnswerImg(event.target.files[0])}
                  className="form-control"
                  type="file"
                  accept="image/png, image/jpeg"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={async () => {
                  const formData = new FormData();
                  formData.append("questionId", qnaId);
                  formData.append("title", answerTitle);
                  formData.append("type", answerType);
                  formData.append("image", answerImg);
                  try {
                    const { data } = await axios.post(
                      "/api/question/admin/info/answer",
                      formData,
                      {
                        headers: { "content-type": "multipart/form-data" },
                      }
                    );
                    if (data.statusCode === 201) {
                      navigate(0);
                    } else {
                      alert(
                        "정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요"
                      );
                    }
                  } catch (e) {
                    if (e.request.status === 400) {
                      const errorMsg = JSON.parse(e.request.response);
                      alert(errorMsg.message);
                    } else {
                      console.log(e);
                    }
                  }
                }}
              >
                추가
              </button>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <main className={"container bg-white"}>
        <div className="pt-3 pb-2 mb-2 border-bottom mt-2">
          <Link
            className="badge rounded-pill bg-secondary mb-2 text-decoration-none text-white"
            to={-1}
          >
            &lsaquo; 목록으로 이동
          </Link>
          <h1 className="h2 fw-bold">Q&A 상세보기</h1>
        </div>
        <div className="my-3">
          <h5>질문영역</h5>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            modifyQnaInfo();
          }}
        >
          <div className="mb-3">
            <label htmlFor="qnaIdInput" className="form-label">
              id
            </label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              id="qnaIdInput"
              value={qnaId}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="qnaNameInput" className="form-label">
              질문내용
            </label>
            <input
              disabled={true}
              defaultValue={qnaTitle}
              type="text"
              className="form-control"
              id="qnaNameInput"
              required={true}
              onChange={(event) => setQnaTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="qnaTypeInput" className="form-label">
              질문타입
            </label>
            <input
              disabled={true}
              defaultValue={qnaType}
              type="text"
              className="form-control"
              id="qnaTypeInput"
              required={true}
              onChange={(event) => setQnaTitle(event.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="qnaIsActiveInput" className="form-label">
              공개여부
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={qnaShowYn}
              onChange={(event) => setQnaShowYn(event.target.value)}
            >
              <option value={true}>공개</option>
              <option value={false}>비공개</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">이미지</label>
            {qnaImg ? (
              <>
                <img
                  className={"d-block img-thumbnail mb-3"}
                  style={{ height: "100px" }}
                  src={qnaImg.url}
                />

                <div className={"input-group"}>
                  <input
                    onChange={(event) => {
                      changeQnaImg(event.target.files[0]);
                    }}
                    id={"qnaImgInput"}
                    className="form-control"
                    type="file"
                    accept="image/png, image/jpeg"
                  />
                  <button
                    className={"btn btn-dark"}
                    onClick={() => {
                      deleteQnaImg();
                    }}
                    type={"button"}
                  >
                    파일삭제
                  </button>
                </div>
              </>
            ) : (
              <>
                <input
                  onChange={(event) => {
                    changeQnaImg(event.target.files[0]);
                  }}
                  id={"qnaImgInput"}
                  className="form-control"
                  type="file"
                  accept="image/png, image/jpeg"
                />
              </>
            )}
          </div>
          <hr />
          <div className="mb-3 d-flex justify-content-between">
            <h5>답변영역</h5>
            {!qnaIsConfirmed ? (
              <div className="">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  + 답변추가
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          {qnaAnswers.map((i, index) => (
            <div className={"row bg-light mb-4 border border-dark"} key={index}>
              <div className="modal-header rounded-0 border-bottom-0 bg-dark mb-3 text-white">
                <h5 className="modal-title">등록답변 {index + 1}</h5>
                {!qnaIsConfirmed ? (
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    onClick={async () => {
                      try {
                        const { data } = await axios.delete(
                          "/api/question/admin/info/answer/" + i.id,
                          {
                            headers: { "content-type": "application/json" },
                          }
                        );
                        if (data.statusCode === 200) {
                          navigate(0);
                        } else {
                          alert(
                            "정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요"
                          );
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 col">
                <label className="form-label">Id</label>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  value={i.id}
                />
              </div>
              <div className="mb-3 col-5">
                <label className="form-label">답변내용</label>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  value={i.title}
                />
              </div>
              <div className="mb-3 col-5">
                <label className="form-label">질문타입</label>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  value={i.type.toString()}
                />
              </div>
              <div className="mb-3 col-4">
                <label className="form-label">이미지</label>
                {i.image ? (
                  <>
                    <img
                      className={"d-block img-thumbnail mb-3"}
                      style={{ height: "100px" }}
                      src={i.image.url}
                    />
                    <label
                      className={"btn btn-primary me-2"}
                      htmlFor={"qnaAnswersImgInput" + i.id}
                    >
                      파일교체
                    </label>
                    <button
                      className={"btn btn-dark"}
                      type={"button"}
                      onClick={() => {
                        deleteAnswerImg(i.id);
                      }}
                    >
                      파일삭제
                    </button>
                    <input
                      onChange={(event) => {
                        changeAnswerImg(i.id, event.target.files[0]);
                      }}
                      id={"qnaAnswersImgInput" + i.id}
                      className="form-control d-none"
                      type="file"
                      accept="image/png, image/jpeg"
                    />
                  </>
                ) : (
                  <>
                    <p
                      className={"d-block img-thumbnail mb-3 w-100 "}
                      style={{ height: "100px" }}
                    >
                      이미지가 없습니다
                    </p>
                    <label
                      className={"btn btn-primary me-2"}
                      htmlFor={"qnaAnswersImgInput" + i.id}
                    >
                      파일추가
                    </label>

                    <input
                      onChange={(event) => {
                        changeAnswerImg(i.id, event.target.files[0]);
                      }}
                      id={"qnaAnswersImgInput" + i.id}
                      className="form-control d-none"
                      type="file"
                      accept="image/png, image/jpeg"
                    />
                  </>
                )}
              </div>
            </div>
          ))}
          <div className={"mb-3"}>
            {!qnaIsConfirmed ? (
              <div
                className="btn btn-primary px-4 w-100"
                onClick={async () => {
                  if (qnaAnswers.length < 2) {
                    alert("2개이상의 질문을 등록해주세요");
                    return false;
                  }
                  try {
                    const { data } = await axios.put(
                      "/api/question/admin/info/" + id,
                      JSON.stringify({
                        isConfirmed: "true",
                      }),
                      {
                        headers: { "content-type": "application/json" },
                      }
                    );
                    if (data.statusCode === 200) {
                      alert("정보수정이 완료되었습니다");
                      navigate(0);
                    } else {
                      alert(
                        "정보 수정중 문제가 발생했습니다. 관리자에게 문의하세요"
                      );
                    }
                  } catch (e) {
                    if (e.request.status === 400) {
                      const errorMsg = JSON.parse(e.request.response);
                      alert(errorMsg.message);
                    } else {
                      console.log(e);
                    }
                  }
                }}
              >
                답변등록완료
              </div>
            ) : (
              <></>
            )}
          </div>

          <Link
            className="btn btn-outline-secondary me-3 px-4"
            type={"button"}
            to={-1}
          >
            목록
          </Link>
          <button className="btn btn-primary px-4" type={"submit"}>
            저장
          </button>
        </form>
      </main>
    </>
  );
};

export default QnaDetail;
