import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const SignUp = () => {
  let navigate = useNavigate();

  const cookies = new Cookies();

  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  // 폼 요청하기
  const adminSignUp = async () => {
    if (password !== passwordCheck) {
      alert("비밀번호와 비밀번호확인이 서로 다릅니다");
      setPassword("");
      setPasswordCheck("");
      return false;
    }
    try {
      const { data } = await axios.post(
        "/api/auth/admin/signup",
        JSON.stringify({
          userName: userName.toString(),
          userId: userId.toString(),
          password: password.toString(),
          phone: phone.toString(),
          email: email.toString(),
        }),
        {
          headers: { "content-type": "application/json" },
        }
      );
      if (data.statusCode === 201) {
        alert("회원가입에 성공했습니다. 관리자 승인을 기다려주세요");
        navigate("/");
      }
    } catch (e) {
      if (e.request.status === 400) {
        alert("이미 존재하는 아이디 또는 이메일입니다.");
      } else {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    if (cookies.get("adminInfo")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="d-flex container-fluid my-5 justify-content-center align-items-center">
        <div className={"col-md-4 col"}>
          <div className={"mb-5"}>
            <span className={"fw-bold"}>이미 계정이 있으신가요? </span>
            <Link to={"/login"} className={"text-decoration-none d-inline "}>
              <span>로그인 하기</span>
            </Link>
          </div>

          <form
            onSubmit={(event) => {
              event.preventDefault();
              adminSignUp();
            }}
          >
            <div className="mb-3">
              <label htmlFor="adminNameInput" className="form-label">
                이름
              </label>
              <input
                pattern="[가-힣]+"
                required={true}
                maxLength={6}
                type="text"
                className="form-control"
                id="adminNameInput"
                placeholder="이기톨"
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminIdInput" className="form-label">
                아이디
              </label>
              <input
                pattern="^[a-zA-Z0-9]+$"
                required={true}
                type="text"
                className="form-control"
                id="adminIdInput"
                placeholder="gittle"
                value={userId}
                onChange={(event) => setUserId(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminPasswordInput" className="form-label">
                비밀번호
              </label>
              <input
                pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$"
                required={true}
                type="password"
                className="form-control"
                id="adminPasswordInput"
                placeholder="숫자, 영문, 특수문자 각 1개 이상 포함 + 8글자 이상"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminPasswordCheckInput" className="form-label">
                비밀번호 확인
              </label>
              <input
                pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$"
                required={true}
                type="password"
                className="form-control"
                id="adminPasswordCheckInput"
                placeholder="비밀번호와 동일하게 입력해주세요"
                value={passwordCheck}
                onChange={(event) => setPasswordCheck(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminPhoneInput" className="form-label">
                휴대폰 번호
              </label>
              <input
                value={phone}
                pattern="\d{11}"
                maxLength={11}
                required={true}
                type="tel"
                className="form-control"
                id="adminPhoneInput"
                placeholder="010xxxxxxxx"
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="adminEmailInput" className="form-label">
                이메일 주소
              </label>
              <input
                value={email}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required={true}
                type="email"
                className="form-control"
                id="adminEmailInput"
                placeholder="help@21market.kr"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <button className="w-100 btn btn-lg btn-primary" type={"submit"}>
              회원가입
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
